import iconHome from '../../assets/icone/home.png'

const initialState = {
  page: {
    title: 'Accueil',
    url: iconHome,
    urlPage: '/accueil',
  },
}

function changePageInfo(state = initialState, action) {
  let nextState

  switch (action.type) {
    case 'PAGE_CHANGE':
      nextState = {
        ...state,
        page: {
          title: action.value.title,
          url: action.value.url,
          urlPage: action.value.urlPage,
        },
      }
      return nextState || state
    default:
      return state
  }
}

export default changePageInfo
