import { Badge, Tooltip } from 'antd'
import { Case, Default, Switch } from 'react-if'
import React, { Component, setGlobal } from 'reactn'
import { faPhoneAlt, faVoicemail } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { makeCall } from './MakeCall'

const TOOLTIP_CALL_THIS = 'Click to call.'
class ContactLineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps() {}

  makeCall = async (element) => {
    const { opened, activeButton } = this.global
    await this.setGlobal({
      opened: !opened,
      activeButton: !activeButton,
    })
    this.setGlobal({
      callingFromWeb: true,
    })
    console.log(element)
    await makeCall(this.props.ua, element.extension, 'Call to')
  }

  render() {
    console.log('this prop')
    const {} = this.state
    const context_there = this
    return (
      <div>
        <Switch>
          <Case
            condition={
              this.props.contact.status === 'Not in use' ||
              this.props.contact.status === 'Not In Use'
            }
          >
            <div className="lines-green">
              <div className="inline">
                <Badge status="success" />
              </div>
              <div style={{ display: 'inline-block', marginRight: '25px' }}>
                {this.props.contact.extension}
              </div>
              <div className="lines-name">{this.props.contact.name}</div>
              <Tooltip title={TOOLTIP_CALL_THIS}>
                <div
                  className="inline right"
                  onClick={() => context_there.makeCall(this.props.contact)}
                >
                  <FontAwesomeIcon icon={faPhoneAlt} className="lines-icon-green" />
                </div>
              </Tooltip>
            </div>
          </Case>
          <Case
            condition={
              this.props.contact.status === 'In Use' ||
              this.props.contact.status === 'In use' ||
              this.props.contact.status === 'In Use'
            }
          >
            <div className="lines-orange">
              <div className="inline">
                <Badge status="warning" />
              </div>
              <div style={{ display: 'inline-block', marginRight: '25px' }}>
                {this.props.contact.extension}
              </div>
              <div className="lines-name">{this.props.contact.name}</div>
              <Tooltip title={TOOLTIP_CALL_THIS}>
                <div
                  className="inline right"
                  onClick={() => context_there.makeCall(this.props.contact)}
                >
                  <FontAwesomeIcon
                    icon={faVoicemail}
                    className="lines-icon-orange"
                  />
                </div>
              </Tooltip>
            </div>
          </Case>
          <Case
            condition={
              this.props.contact.status === 'Unavailable' ||
              this.props.contact.status === 'unavailable'
            }
          >
            <div className="lines-red">
              <div className="inline">
                <Badge status="error" />
              </div>
              <div style={{ display: 'inline-block', marginRight: '25px' }}>
                {this.props.contact.extension}
              </div>
              <div className="lines-name">{this.props.contact.name}</div>
              <Tooltip title={TOOLTIP_CALL_THIS}>
                <div
                  className="inline right"
                  onClick={() => context_there.makeCall(this.props.contact)}
                >
                  <FontAwesomeIcon icon={faVoicemail} className="lines-icon-red" />
                </div>
              </Tooltip>
            </div>
          </Case>
          <Default>
            <div></div>
          </Default>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
  }
}

export default connect(mapStateToProps)(ContactLineComponent)
