import { Divider, Spin, notification } from 'antd'
import React, { Component, setGlobal } from 'reactn'

import { Alert } from 'antd'
import { Row } from 'react-bootstrap'
import Voicemail from './Voicemail'
import { getVoicemails } from '../../../Js/dataUserGeneral'

// Messages handling //
const ERROR_NOTIFICATION_DESC = process.env.REACT_APP_ERROR_NOTIFICATION_DESC
const REFRESH_DATA = process.env.REACT_APP_REFRESH_DATA

class VoicemailPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      VoiceMail: null,
      Have_Voicemail: 'false',
    }
  }

  componentDidMount = async () => {
    try {
      const response = await getVoicemails()
      // Success 🎉
      console.log(response)
      await this.setState({
        VoiceMail: response,
        Have_Voicemail: 'true',
      })
    } catch (error) {
      // Error 😨
      console.log('error')
    }
  }

  handleClickVoicemailPage = async (e) => {
    if (e === 200) {
      this.componentDidMount()
    }
  }

  renderVoicemail = (data) => {
    const context_there = this
    const voicemail_dataset = data
    console.log('voicemail data just before render')
    console.log(data)
    if (this.state.Have_Voicemail === 'true') {
      var voicemail = []
      voicemail_dataset.forEach(function (item) {
        voicemail.push(
          <div>
            <Voicemail key={item.id} infoVoicemail={item} />
          </div>
        )
      })
      return voicemail
    }
    return (
      <div className="text-left">
        <h4>
          <Spin tip="Chargement..." />
        </h4>
      </div>
    )
  }

  render() {
    return (
      <div id="voicemail">
        <Row>
          <div className="users-col-userspage">
            <div id="voicemail">
              <h3 style={{ textTransform: 'uppercase' }}>Répondeur</h3>
              <Divider />
              <div className="voicemail_holder">
                {this.renderVoicemail(this.state.VoiceMail)}
              </div>
            </div>
          </div>
        </Row>
      </div>
    )
  }
}

export default VoicemailPage
