import axios from 'axios'
import { checkLocalStorage } from './dataUserGeneral'

const getValueDnd = async () => {
  const { token, domainName } = await checkLocalStorage()
  const ownExtension = JSON.parse(localStorage.getItem('user'))
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get('https://' + domainName + ':8082/dnd/' + ownExtension.userInfo.extension, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const getValueDndWeb = async () => {
  const { token, domainName } = await checkLocalStorage()
  const ownExtension = JSON.parse(localStorage.getItem('user'))
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(
      'https://' + domainName + ':8082/dnd/web/' + ownExtension.userInfo.extension,
      {
        headers: {
          Authorization: `Bearer ` + token,
          'Strict-Transport-Security': '1000',
          'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const setDndOn = async () => {
  const { token, domainName } = await checkLocalStorage()
  const ownExtension = JSON.parse(localStorage.getItem('user'))
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(
      'https://' +
        domainName +
        ':8082/dnd/set/on/' +
        ownExtension.userInfo.extension,
      {
        headers: {
          Authorization: `Bearer ` + token,
          'Strict-Transport-Security': '1000',
          'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const setDndOff = async () => {
  const { token, domainName } = await checkLocalStorage()
  const ownExtension = JSON.parse(localStorage.getItem('user'))
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(
      'https://' +
        domainName +
        ':8082/dnd/set/off/' +
        ownExtension.userInfo.extension,
      {
        headers: {
          Authorization: `Bearer ` + token,
          'Strict-Transport-Security': '1000',
          'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

export { getValueDnd, getValueDndWeb, setDndOn, setDndOff }
