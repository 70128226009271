const initialState = {
  session: [],
}

function saveSession(state = initialState, action) {
  switch (action.type) {
    case 'ADD_CALL_SESSION':
      return {
        ...state,
        session: [...state.session, action.value.session],
      }
    case 'MODIFY_CALL_SESSION':
      return {
        ...state,
        session: state.session.map((session) => {
          if (session._id === action.value.session._id) {
            return { ...session, _status: action.value.session._status }
          }
          return session
        }),
      }
    case 'DELETE_CALL_SESSION':
      let call = state.session.filter((item) => item.id !== action.value.session.id)
      if (call === null) {
        return {
          ...state,
          session: [],
        }
      }
      return {
        session: call,
      }
    default:
      return state
  }
}

export default saveSession
