import { faPhoneAlt, faVoicemail } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Divider,
  notification,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import moment from 'moment'
import { Row } from 'react-bootstrap'
import { Case, Default, Else, If, Switch, Then } from 'react-if'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import React, { Component } from 'reactn'
import {
  checkLocalStorage,
  getCallRecentLegacy,
  getHistoryquesCall,
} from '../../../Js/dataUserGeneral'
import { makeCall } from './MakeCall'
const PhoneNumber = require('awesome-phonenumber')
const lookup = require('country-code-lookup')
var arraySort = require('array-sort')
var arrSort = require('arr-sort')

// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT

// Messages handling //
const ERROR_NOTIFICATION_DESC = process.env.REACT_APP_ERROR_NOTIFICATION_DESC
const REFRESH_DATA = process.env.REACT_APP_REFRESH_DATA
const REFRESH_DATA_DESC = process.env.REACT_APP_REFRESH_DATA_DESC
const TOOLTIP_CALL_THIS = 'Cliquez pour appeler.'
class History extends Component {
  intervalID
  constructor(props) {
    super(props)
    this.state = {
      data_actual_user: null,
      data_logs: null,
      data_logs_status_unsorted: null,
    }
  }

  componentDidMount = async () => {
    await this.sendMessage()
    await this.fetch_user_logs()
    notification.open({
      type: 'success',
      message: REFRESH_DATA,
      description: REFRESH_DATA_DESC,
    })
  }

  sendMessage = async () => {
    const ws = this.global.ws
    const own_extension = JSON.parse(localStorage.getItem('user'))
    const { token } = await checkLocalStorage()
    try {
      ws.send(
        JSON.stringify({
          token: token,
          message: 'getCoworkers',
          clientId: own_extension.userInfo.extension,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  makeCall = async (element) => {
    const { opened, activeButton } = this.global
    await this.setGlobal({
      opened: !opened,
      activeButton: !activeButton,
    })
    if (element.incomingCall) {
      // this.props.ua = info number perso // from / to = destinataire // last = useless
      await makeCall(this.props.ua, element.from, 'Call from')
    } else {
      await makeCall(this.props.ua, element.to, 'Call to')
    }
    this.fetch_user_logs()
  }
  fetch_user_logs = async () => {
    try {
      let Legacy = await getCallRecentLegacy()
      console.log(Legacy)
    } catch (error) {
      console.log(error)
      this.setState({
        no_logs: 'true',
      })
      return error
    }

    try {
      let Call_log = await getHistoryquesCall()
      console.log(Call_log)

      if (Call_log !== null || Call_log.status != 500 || Call_log.status != 404) {
        this.setState({
          data_logs: Call_log.reverse(),
        })
      }
    } catch (error) {
      console.log(error)
      this.setState({ no_logs: 'true' })
      return error
    }
    this.intervalID = setTimeout(this.fetch_user_logs.bind(this), 10000)
  }

  renderCallLogs = (data) => {
    const context_there = this
    const columns = [
      {
        title: 'De',
        dataIndex: 'from',
        key: 'from',
        render: (text, record) => (
          <Switch>
            <Case condition={this.global.data_actual_user.extension === text}>
              <div>
                Vous
                <Tag style={{ marginLeft: '5px' }} color={'black'}>
                  {'Nextcall'.toUpperCase()}
                </Tag>
                <div
                  style={{ color: '#149995', fontSize: '10px', fontWeight: 'bold' }}
                >
                  <If condition={record.countryCode_from}>
                    <Then>
                      {lookup.byInternet(record.countryCode_to || 'FR').country}
                    </Then>
                    <Else>FR</Else>
                  </If>
                </div>
              </div>
            </Case>
            <Case
              condition={
                this.global.data_actual_user.extension !== text &&
                !record.from_origin_nc
              }
            >
              <div>
                {text}
                <Tag style={{ marginLeft: '5px' }} color={'black'}>
                  {'Externe'.toUpperCase()}
                </Tag>
                <div
                  style={{ color: '#149995', fontSize: '10px', fontWeight: 'bold' }}
                >
                  <If condition={record.countryCode_from}>
                    <Then>
                      {lookup.byInternet(record.countryCode_to || 'FR').country}
                    </Then>
                    <Else>FR</Else>
                  </If>
                </div>
              </div>
            </Case>
            <Case
              condition={
                this.global.data_actual_user.extension !== text &&
                record.from_origin_nc
              }
            >
              <div>
                {text}
                <Tag style={{ marginLeft: '5px' }} color={'black'}>
                  {'Nextcall'.toUpperCase()}
                </Tag>
                <div
                  style={{ color: '#149995', fontSize: '10px', fontWeight: 'bold' }}
                >
                  <If condition={record.countryCode_from}>
                    <Then>
                      {lookup.byInternet(record.countryCode_to || 'FR').country}
                    </Then>
                    <Else>FR</Else>
                  </If>
                </div>
              </div>
            </Case>
            <Default>
              <div>{text}</div>
            </Default>
          </Switch>
        ),
      },
      {
        title: 'Vers',
        dataIndex: 'to',
        key: 'to',
        render: (text, record) => (
          <div>
            <Switch>
              <Case condition={this.global.data_actual_user.extension === text}>
                <div>
                  Vous
                  <Tag style={{ marginLeft: '5px' }} color={'black'}>
                    {'Nextcall'.toUpperCase()}
                  </Tag>
                  <div
                    style={{
                      color: '#149995',
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    <If condition={record.countryCode_to}>
                      <Then>
                        {lookup.byInternet(record.countryCode_to || 'FR').country}
                      </Then>
                      <Else>FR</Else>
                    </If>
                  </div>
                </div>
              </Case>
              <Case
                condition={
                  this.global.data_actual_user.extension !== text &&
                  !record.to_origin_nc
                }
              >
                <div>
                  {text}
                  <Tag style={{ marginLeft: '5px' }} color={'black'}>
                    {'Externe'.toUpperCase()}
                  </Tag>
                  <div
                    style={{
                      color: '#149995',
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    <If condition={record.countryCode_to}>
                      <Then>
                        {lookup.byInternet(record.countryCode_to || 'FR').country}
                      </Then>
                      <Else>FR</Else>
                    </If>
                  </div>
                </div>
              </Case>
              <Case
                condition={
                  this.global.data_actual_user.extension !== text &&
                  record.to_origin_nc
                }
              >
                <div>
                  {text}
                  <Tag style={{ marginLeft: '5px' }} color={'black'}>
                    {'Nextcall'.toUpperCase()}
                  </Tag>
                  <div
                    style={{
                      color: '#149995',
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    <If condition={record.countryCode_to}>
                      <Then>
                        {lookup.byInternet(record.countryCode_to || 'FR').country}
                      </Then>
                      <Else>FR</Else>
                    </If>
                  </div>
                </div>
              </Case>
              <Default>
                <div>{text}</div>
              </Default>
            </Switch>
          </div>
        ),
      },
      {
        title: 'Début appel',
        dataIndex: 'beginCall',
        key: 'beginCall',
        render: (text) => <Moment format="DD/MM/YYYY HH:mm">{text}</Moment>,
      },
      {
        //   <Moment format="DD/MM/YYYY HH:mm">{item.beginCall}</Moment>
        title: 'Fin appel',
        dataIndex: 'endCall',
        key: 'endCall',
        render: (text) => <Moment format="DD/MM/YYYY HH:mm">{text}</Moment>,
      },
      {
        title: 'Durée',
        dataIndex: 'endCall',
        key: 'endCall',
        render: (text, record) => (
          <Moment duration={record.beginCall} date={record.endCall} />
        ),
      },
      /*   {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <>
        {tags.map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  }, */
      {
        title: 'État',
        key: 'status',
        render: (text, record) => (
          <Switch>
            <Case condition={record.status === 'ANSWERED'}>
              <div>
                Répondu{' '}
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="lines-icon-green-logs"
                />
              </div>
            </Case>
            <Case condition={record.status === 'NO ANSWER'}>
              <div>
                Pas de réponse{' '}
                <FontAwesomeIcon
                  icon={faVoicemail}
                  className="lines-icon-red-logs"
                />
              </div>
            </Case>
            <Default>
              <div></div>
            </Default>
          </Switch>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title={TOOLTIP_CALL_THIS} placement="left">
              <div className="logs-callback inline">
                <Button
                  className="logs-callback-button"
                  onClick={() => context_there.makeCall(record)}
                >
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="lines-icon-green-logs"
                  />
                </Button>
              </div>
            </Tooltip>
          </Space>
        ),
      },
    ]
    if (data) {
      console.log('RendercallLogs on top')
      console.log(data)
      console.log('context_there.global.data_lines_status_unsorted')
      console.log(context_there.global.data_lines_status_unsorted)
      const logs_dataset = data
      var logs = []
      logs_dataset.slice(-10000).forEach(function (item) {
        console.log(item)
        let result_from = context_there.global.data_lines_status_unsorted.find((i) =>
          i.extension.includes(item.from)
        )
        var rf = ''
        let result_to = context_there.global.data_lines_status_unsorted.find((i) =>
          i.extension.includes(item.to)
        )
        var rt = ''
        if (result_from) {
          rf = result_from || 'Inconnu'
        }
        if (result_to) {
          rt = result_to || 'Inconnu'
        }
        //
        let phone_to_parse_original
        let phone_to_parse_asString
        let phone_to_parse_plus
        let from_isNextcall
        let to_isNextcall

        if (item.from.length === 4) {
          from_isNextcall = true
        } else {
          from_isNextcall = false
        }

        if (item.to.length === 4) {
          to_isNextcall = true
        } else {
          to_isNextcall = false
        }

        if (item.from.toString().startsWith('000')) {
          console.log('Prefixed Call')
          phone_to_parse_original = parseInt(item.from)
          phone_to_parse_asString = phone_to_parse_original.toString()
          phone_to_parse_plus = '+' + phone_to_parse_asString
        } else {
          console.log('French call with no prefix, default')
          phone_to_parse_original = parseInt(item.from)
          phone_to_parse_asString = '33' + phone_to_parse_original.toString()
          phone_to_parse_plus = '+' + phone_to_parse_asString
        }

        const detectedCountryCode_from = PhoneNumber(
          phone_to_parse_plus
        ).getRegionCode()
        const countryCode_from = detectedCountryCode_from

        if (item.to.toString().startsWith('000')) {
          console.log('Prefixed Call')
          phone_to_parse_original = parseInt(item.to)
          phone_to_parse_asString = phone_to_parse_original.toString()
          phone_to_parse_plus = '+' + phone_to_parse_asString
        } else {
          console.log('French call with no prefix, default')
          phone_to_parse_original = parseInt(item.to)
          phone_to_parse_asString = '33' + phone_to_parse_original.toString()
          phone_to_parse_plus = '+' + phone_to_parse_asString
        }

        const detectedCountryCode_to = PhoneNumber(
          phone_to_parse_plus
        ).getRegionCode()
        const countryCode_to = detectedCountryCode_to
        //
        let duration = moment.duration(
          moment(item.endCall).diff(moment(item.beginCall))
        )

        let final_item = item
        final_item.duration = duration
        final_item.result_from = rf
        final_item.result_to = rt
        final_item.countryCode_from = countryCode_from
        final_item.countryCode_to = countryCode_to
        final_item.from_origin_nc = from_isNextcall
        final_item.to_origin_nc = to_isNextcall
        console.log(final_item)
        logs.push(final_item)
      })
      return (
        <div style={{ paddingRight: '10px' }}>
          <Table columns={columns} dataSource={logs} />
        </div>
      )
    }
    return (
      <If condition={this.state.no_logs === 'true'}>
        <Then>
          <div className="white text-center">Rien pour le moment.</div>
        </Then>
        <Else>
          {' '}
          <div className="text-center">
            <Spin tip="Chargement..."></Spin>
          </div>
        </Else>
      </If>
    )
  }

  componentWillUnmount() {
    clearTimeout(this.intervalID)
  }

  render() {
    return (
      <div id="history">
        <Row>
          <div className="users-col-userspage">
            <div id="history">
              <h3 style={{ textTransform: 'uppercase' }}>Journal d'appel</h3>
              <Divider />
              <div id="content_history">
                {this.renderCallLogs(this.state.data_logs)}
              </div>
            </div>
          </div>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
  }
}

export default connect(mapStateToProps)(History)
