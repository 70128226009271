import '../../App.scss'

import { Input, Layout, Menu, Typography } from 'antd'
import React, { Component, setGlobal } from 'reactn'
import { Redirect, withRouter } from 'react-router-dom'

import { AddAccountUa } from '../../action/uaConnection'
import AddContactPage from './BodyPage/AddContactPage'
import ButtonKeyBoard from './ButtonKeyBoard/ButtonKeyBoard'
import ContactsPage from './BodyPage/ContactsPage'
import History from './BodyPage/History'
import HomePage from './BodyPage/Home'
import Login from '../Login'
import { MenuComponent } from '../MenuComponent'
import TopbarComponent from '../TopbarComponent'
import Users from './BodyPage/Users'
import VoicemailPage from './BodyPage/VoicemailPage'
import { connect } from 'react-redux'
import { rem } from 'polished'
import styled from 'styled-components'

const { Search } = Input

const { Title } = Typography

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

const HEAD_TITLE = 'NextCall Dashboard'
const ContainerPage = styled.div`
  margin-left: ${rem(445)};
  margin-right: ${rem(5)};

  @media only screen and (max-width: 1400px) {
    margin-left: ${rem(305)};
  }

  @media only screen and (max-width: 800px) {
    margin-left: ${rem(5)};
    margin-right: ${rem(5)};
  }

  @media only screen and (max-width: 660px) {
    margin-left: ${rem(0)};
    margin-right: ${rem(0)};
  }
`

const PageWithRouter = withRouter((props) => <Page {...props} />)

class Page extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popUpCall: false,
      managementCall: false,
      sessionCall: false,
      checkLocalStorage: false,
    }
  }

  componentDidMount = async () => {
    await this.checkLocalStorage()
  }

  checkLocalStorage = async () => {
    let infoUser = await JSON.parse(localStorage.getItem('user'))
    if (infoUser === null) {
      localStorage.clear()
      this.setState({
        checkLocalStorage: true,
      })
    } else {
      if (typeof infoUser.userInfo === 'undefined') {
        localStorage.clear()
        this.setState({
          checkLocalStorage: true,
        })
      } else {
        if (
          typeof infoUser.userInfo.parentName === 'undefined' ||
          typeof infoUser.userInfo.extension === 'undefined' ||
          typeof infoUser.userInfo.asteriskSecret === 'undefined'
        ) {
          localStorage.clear()
          this.setState({
            checkLocalStorage: true,
          })
        }
      }
    }
  }

  checkConnectionASterisk = () => {}

  displayGoodPage() {
    const parseUrl = this.props.location.pathname.replace('/', '').split('/')
    if (parseUrl.length === 1) {
      switch (parseUrl[0]) {
        case 'dashboard':
          return <HomePage />
        case 'contacts':
          return <ContactsPage />
        case 'history':
          return <History />
        case 'voicemail':
          return <VoicemailPage />
        case 'users':
          return <Users />
        default:
          return <HomePage />
      }
    } else {
      switch (this.props.location.pathname) {
        case '/contacts/add':
          return <AddContactPage pathname="add" />
        case '/contacts/modify':
          return (
            <AddContactPage
              pathname="modify"
              infoContact={this.props.location.state}
            />
          )
        default:
          return <HomePage />
      }
    }
  }

  permissionNotification = () => {
    if (!('Notification' in window)) {
      alert('this browser does not support notification .')
    } else {
      if (Notification.permission !== 'granted') Notification.requestPermission()
    }
  }

  checkIfUserIsConnected = () => {
    this.permissionNotification()
    return (
      <div>
        <Layout
          className="site-layout"
          id="main-layout-container"
          style={{ marginLeft: 230 }}
        >
          <TopbarComponent />
          <Content
            className="main-content-area"
            style={{
              paddingLeft: '35px',
              marginTop: '25px',
              minHeight: '100rem',
              maxHeight: '100%',
            }}
          >
            {this.displayGoodPage()}
          </Content>
          <Footer
            className="footer"
            style={{ textAlign: 'center', marginleft: '-240px' }}
          >
            Nextcall 2020
          </Footer>
        </Layout>
        <ButtonKeyBoard />
      </div>
    )
  }

  render() {
    this.checkConnectionASterisk()
    return (
      <div>
        {this.props.location.pathname === '/login' ? null : (
          <div>
            <MenuComponent />
          </div>
        )}
        {this.props.location.pathname === '/login' ? (
          <Login />
        ) : (
          this.checkIfUserIsConnected()
        )}
        {this.state.checkLocalStorage ? <Redirect to="/login" /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
    dataConnectionServer: state.dataConnectionServer.dataConnectionServer,
  }
}

export default connect(mapStateToProps, { AddAccountUa })(withRouter(PageWithRouter))
