import { Button, Checkbox, Form, Input } from 'antd'
import { Case, Default, Else, If, Switch, Then } from 'react-if'
import { Divider, Tooltip } from 'antd'
import { Media, Player, controls } from 'react-media-player'
import React, { Component, createRef, setGlobal } from 'reactn'
import { deleteVoiceMail, playVoicemailAudio } from './../../../Js/dataUserGeneral'
import {
  faDownload,
  faMinusCircle,
  faPause,
  faPhoneAlt,
  faPlay,
} from '@fortawesome/free-solid-svg-icons'

import AuthenticationLink from './DownloadVoicemail'
import { Badge } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import ReactAudioPlayer from 'react-audio-player'
import { Redirect } from 'react-router-dom'
import Sound from 'react-sound'
import { connect } from 'react-redux'
import { makeCall } from './MakeCall'
import moment from 'moment'
import { rem } from 'polished'
import styled from 'styled-components'

const { PlayPause, MuteUnmute, Progress } = controls
const TOOLTIP_CALL_THIS = 'Click to call.'
const TheVoicemail = styled.div`
  display: flex;
  border-bottom: 1px solid #292929;
  justify-content: space-around;

  .fa-play {
    flex: 1;
    color: #292929;
    width: ${rem(30)};
    height: ${rem(25)};
    margin: auto 0;
  }

  .fa-pause {
    flex: 1;
    color: #292929;
    width: ${rem(30)};
    height: ${rem(25)};
    margin: auto 0;
  }

  .fa-download {
    color: #292929;
    width: ${rem(30)};
    height: ${rem(25)};
    align-items: center;
  }

  .fa-phone-alt {
    flex: 1;
    color: #292929;
    width: ${rem(30)};
    height: ${rem(25)};
    margin: auto 0;
  }

  .fa-minus-circle {
    flex: 1;
    color: #f65552;
    width: ${rem(30)};
    height: ${rem(25)};
    margin: auto 0;
  }

  path {
    cursor: pointer;
  }
`

const From = styled.p`
  flex: 2;
  margin-left: ${rem(10)};
`

const Number = styled.p`
  flex: 2;
`

const Date = styled.p`
  flex: 2;
  text-align: center;
`

const Duration = styled.p`
  flex: 2;
  text-align: center;
`

class Voicemail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      IsPlay: false,
      IsOk: false,
      controlled: true,
      currentSong: this.props.infoVoicemail.fileUrl,
      position: 0,
      volume: 100,
      playbackRate: 1,
      loop: false,
      playStatus: Sound.status.PLAYING,
    }
    this.link = createRef()
  }

  makeCall = async (element) => {
    if (this.global.makeCall_clicked) {
      console.log('Already clicked MakeCall')
    } else {
      await this.setGlobal({
        opened: true,
        makeCall_clicked: true,
      })
      await makeCall(this.props.ua, element, 'Call to')
    }
  }

  deleteVoicemail = async (e) => {
    let nameFile = this.props.infoVoicemail.audioFile.split('/')
    let reponse = await deleteVoiceMail(nameFile[3])
    if (reponse.status === 200) {
      this.setState({
        IsOk: true,
      })
      this.props.onClick(reponse.status)
    }
  }

  renderPlayer = (data) => {
    const context_there = this
    if (data) {
      var player_div = []
      player_div.push(
        <p style={{ display: 'inline-block' }}>
          {' '}
          <Media>
            <div className="media">
              <div className="media-player">
                <Player src={this.props.infoVoicemail.fileUrl} />
              </div>
              <div className="media-controls">
                <PlayPause />
              </div>
            </div>
          </Media>
        </p>
      )
    } else {
      var player_div = []
      player_div.push(
        <p style={{ display: 'inline-block' }}>
          <i>Fichier illisible</i>
        </p>
      )
    }
    return player_div
  }

  renderDuration = (data) => {
    const context_there = this
    var duration_div = []
    if (data && data !== 'undefined') {
      duration_div.push(
        <div
          style={{
            display: 'inline-block',
            float: 'right',
            fontWeight: '600',
          }}
        >
          {this.props.infoVoicemail.duration}
        </div>
      )
    } else {
      duration_div = []
      duration_div.push(<p></p>)
    }
    return duration_div
  }

  displayControllerSound = async (e) => {
    return (
      <Media>
        <div className="media" style={{ marginLeft: '15px' }}>
          <div className="media-player">
            <Player src={this.props.infoVoicemail.fileUrl} />
          </div>
          <div className="media-controls">
            <PlayPause />
            <MuteUnmute />
          </div>
        </div>
      </Media>
    )
  }

  render() {
    const context_there = this
    const isCurrentDate = moment(this.props.infoVoicemail.fileCreationDate).isSame(
      moment(),
      'day'
    )

    return (
      <div className="contact-card">
        <If condition={isCurrentDate}>
          <Then>
            <Tooltip title={'Nouveau message'}>
              <Badge dot style={{ marginTop: '-6px', left: '-1px' }}>
                {' '}
              </Badge>{' '}
            </Tooltip>
            <div className="contact-card-name" style={{ paddingLeft: '10px' }}>
              {this.props.infoVoicemail.from_name} | Le{' '}
              <b>
                <Moment format="DD/MM/YYYY HH:mm">
                  {this.props.infoVoicemail.fileCreationDate}
                </Moment>
              </b>
            </div>
          </Then>
          <Else>
            <div className="contact-card-name" style={{ paddingLeft: '10px' }}>
              {this.props.infoVoicemail.from_name} | Le{' '}
              <b>
                <Moment format="DD/MM/YYYY HH:mm">
                  {this.props.infoVoicemail.fileCreationDate}
                </Moment>
              </b>
            </div>
          </Else>
        </If>
        <Tooltip title={TOOLTIP_CALL_THIS}>
          <div
            style={{
              display: 'inline-block',
              marginRight: '5px',
              float: 'right',
              cursor: 'pointer',
            }}
            onClick={() =>
              context_there.makeCall(this.props.infoVoicemail.from_number)
            }
          >
            {this.props.infoVoicemail.from_number}
            <FontAwesomeIcon
              icon={faPhoneAlt}
              className="lines-icon-green-contact-card"
            />
          </div>
        </Tooltip>
        <Divider className="small-divider" />
        {this.renderPlayer(this.props.infoVoicemail.from_name)}
        {this.renderDuration(this.props.infoVoicemail.from_name)}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
  }
}

export default connect(mapStateToProps)(Voicemail)
