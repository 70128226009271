// ACTION REDUX FOR CONNECTION TO ASTERISK
import {
  ACTUEL_INFO_SERVE,
  ADD_INFO_SERVE,
} from '../components/utils/InfoServeConstant'

export function AddInfoServe(serve) {
  return {
    type: ADD_INFO_SERVE,
    serve: serve,
  }
}

export function getActuelConnectionUa(serve) {
  return {
    type: ACTUEL_INFO_SERVE,
    serve: serve,
  }
}
