import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Badge,
  Checkbox,
  Divider,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
  Select,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { Icon as React_Icons } from 'react-icons-kit'
import { exit } from 'react-icons-kit/icomoon/exit'
import { Else, If, Then, Switch, Case, Default } from 'react-if'
import { connect } from 'react-redux'
import React from 'reactn'
import { history } from '../action/history'
import { AddInfoServe } from '../action/infoServe'
import { AddAccountUa } from '../action/uaConnection'
import '../App.scss'
import { checkLocalStorage } from '../Js/dataUserGeneral'
import { getValueDnd, getValueDndWeb, setDndOff, setDndOn } from '../Js/dnd'

const { confirm } = Modal
const { Search } = Input
const { Title } = Typography
const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

const { Option } = Select

// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT

const HEAD_TITLE = 'NextCall Dashboard'
const SUCCESS_NOTIFICATION_TITLE = 'OK ! Action successfully executed.'
const SUCCESS_NOTIFICATION_DESC = 'The data should already be refreshed.'
const ERROR_NOTIFICATION_DESC =
  'Something happened during this action, please try again'
const SAVE_BUTTON = 'SAVE CHANGES'
const CORRECT_FIELD_TEXT = 'Please correct this field.'
const DND_ON = 'You are on Do not disturb, deactivate ?'
const DND_OFF = 'Do you want to activate Do not disturb ?'
const LIVE_ON = 'You are online and data feed is working fine.'
const LIVE_OFF =
  'You are data feed is offline and the data on the pages will only be refresh on change of page.'

var arraySort = require('array-sort')
class TopbarComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      a_u: null,
      ws_co: false,
    }
  }

  sendMessage = async () => {
    const ws = this.global.ws
    const own_extension = JSON.parse(localStorage.getItem('user'))
    const { token } = await checkLocalStorage()
    try {
      ws.send(
        JSON.stringify({
          token: token,
          message: 'getCoworkers',
          clientId: own_extension.userInfo.extension,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  onChange = async (e) => {
    console.log(`checked = ${e.target.checked}`)
    await localStorage.setItem('entryCallRing', e.target.checked)
    await this.setGlobal({
      entryCallRing: e.target.checked,
    })
  }

  componentWillMount = async () => {
    console.log(this.global.data_actual_user)
    const a_u = JSON.parse(localStorage.getItem('data_actual_user'))
    const dnd = await getValueDnd()
    const lineStatus = await getValueDndWeb()
    console.log('DND VALUE')
    console.log(dnd)
    await this.setState({
      a_u: a_u,
    })
    await this.setGlobal({
      dnd_status: dnd,
      lineStatus: lineStatus,
    })
    this.connect()
    this.sendMessage()
  }

  componentDidMount() {
    var intervalId = setInterval(this.refreshPage, 900000)
    var dndref = setInterval(this.refreshDnd, 2000)
  }

  refreshDnd = async () => {
    const dnd = await getValueDnd()
    const lineStatus = await getValueDndWeb()
    await this.setGlobal({
      dnd_status: dnd,
      lineStatus: lineStatus,
    })
  }

  refreshPage = async () => {
    console.log(this.global)
    if (this.global.in_communication) {
      console.log('Refresh skipped user in communication')
    } else {
      console.log('Refresh page')
      window.location.reload(false)
    }
  }

  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = async () => {
    console.log('CONNECT TopbarComponent')
    const own_extension = JSON.parse(localStorage.getItem('user'))

    let ws
    if (SRV_ENDPOINT === 'srv.petitefriture.nextcall.io') {
      ws = new WebSocket(
        //"ws:/localhost:3004/" + localStorage.getItem('uuid')
        'wss:/' + WSS_ENDPOINT + ':3003/' + own_extension.userInfo.extension
      )
    } else {
      ws = new WebSocket(
        //"ws:/localhost:3004/" + localStorage.getItem('uuid')
        'wss:/' + WSS_ENDPOINT + ':3003/' + own_extension.userInfo.extension
      )
    }

    let that = this
    var connectInterval

    ws.onopen = async () => {
      console.log('CONNECTED TO WEBSOCKET')
      await this.setGlobal({
        ws: ws,
      })
      await this.setState({
        ws_co: true,
      })
      that.timeout = 250
      clearTimeout(connectInterval)
    }

    ws.onmessage = async (e) => {
      const data = JSON.parse(e.data)
      console.log(data)
      if (data !== null) {
        this.setGlobal({
          data_lines_status: arraySort(data, 'status'),
          data_lines_status_unsorted: data,
        })
      }
    }

    ws.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      )
      this.setState({
        ws_co: false,
      })
      that.timeout = that.timeout + that.timeout
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout))
    }

    ws.onerror = (err) => {
      console.error('Socket encountered error: ', err.message, 'Closing socket')
      this.setState({
        ws_co: false,
      })
      ws.close()
    }
  }

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state
    if (!ws || ws.readyState == WebSocket.CLOSED) this.connect() //check if websocket instance is closed, if so call `connect` function.
  }

  showConfirmSetDND = async () => {
    const context_there = this
    confirm({
      title: 'Activate Do not disturb ?',
      icon: <ExclamationCircleOutlined />,
      content:
        'You can use the Do not disturb feature so that your softphone does not emit any sound signaling a call, an alert message or a notification',
      onOk() {
        setDndOn()
        context_there.setGlobal({
          dnd_status: true,
        })
        notification.open({
          type: 'success',
          message: SUCCESS_NOTIFICATION_TITLE,
          description: SUCCESS_NOTIFICATION_DESC,
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  showConfirmUnsetDND = async () => {
    const context_there = this
    confirm({
      title: 'Deactivate Do not disturb ?',
      icon: <ExclamationCircleOutlined />,
      content:
        'You can use the Do not disturb feature so that your softphone does not emit any sound signaling a call, an alert message or a notification',
      onOk() {
        setDndOff()
        context_there.setGlobal({
          dnd_status: false,
        })
        notification.open({
          type: 'success',
          message: SUCCESS_NOTIFICATION_TITLE,
          description: SUCCESS_NOTIFICATION_DESC,
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  handleLogout() {
    localStorage.removeItem('user')
    const ws = this.global.ws
    const own_extension = JSON.parse(localStorage.getItem('user'))
    try {
      ws.send(
        JSON.stringify({
          message: 'disconnect',
          clientId: own_extension.userInfo.extension,
        })
      )
    } catch (error) {
      console.log(error)
    }
    this.props.ua.stop()
    localStorage.clear()
    this.setState({
      exitToApp: true,
    })
    history.push('/login')
  }

  handleStopFetch() {
    const ws = this.global.ws
    const own_extension = JSON.parse(localStorage.getItem('user'))
    try {
      ws.send(
        JSON.stringify({
          message: 'disconnect',
          clientId: own_extension.userInfo.extension,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {} = this.state
    const { ws } = this.global
    const data_context = JSON.parse(localStorage.getItem('parentContext'))
    return (
      <div>
        <Header className="site-layout-background header-on-top">
          <div
            style={{
              float: 'left',
              display: 'inline-block',
            }}
          >
            {' '}
          </div>{' '}
          <div
            style={{
              float: 'right',
              display: 'inline-block',
            }}
          >
            <div
              style={{
                display: 'inline-block',
              }}
            >
              Bienvenue{' '}
              {JSON.parse(localStorage.getItem('data_actual_user')).first_name}{' '}
              {JSON.parse(localStorage.getItem('data_actual_user')).last_name}{' '}
              <Divider type="vertical" />
            </div>{' '}
            <div
              style={{
                display: 'inline-block',
                marginRight: '0px',
              }}
            >
              {' '}
              <If condition={this.state.ws_co}>
                <Then>
                  <Tooltip title={LIVE_ON}>
                    <Tag
                      style={{
                        left: '5px',
                        top: '-1px',
                        position: 'relative',
                      }}
                      color="green"
                    >
                      Connecté en temps réel{' '}
                    </Tag>{' '}
                  </Tooltip>{' '}
                </Then>{' '}
                <Else>
                  <Tooltip title={LIVE_OFF}>
                    <Tag
                      style={{
                        left: '5px',
                        top: '-1px',
                        position: 'relative',
                      }}
                      color="red"
                    >
                      Problème de connexion en temps réel{' '}
                    </Tag>{' '}
                  </Tooltip>{' '}
                </Else>{' '}
              </If>{' '}
              <Divider type="vertical" />
            </div>{' '}
            <div
              style={{
                display: 'inline-block',
                marginRight: '15px',
              }}
            >
              <If condition={this.global.lineStatus || this.global.callingFromWeb}>
                <Then>
                  {' '}
                  <div className="inline">
                    Votre ligne est{' '}
                    <Tag
                      style={{
                        left: '5px',
                        top: '-1px',
                        position: 'relative',
                      }}
                      color="orange"
                    >
                      occupé{' '}
                    </Tag>{' '}
                  </div>{' '}
                  <Divider type="vertical" />
                </Then>{' '}
                <Else>
                  {' '}
                  Ligne{' '}
                  <Tag
                    style={{
                      left: '5px',
                      top: '-1px',
                      position: 'relative',
                    }}
                    color="green"
                  >
                    disponible{' '}
                  </Tag>{' '}
                  <Divider type="vertical" />
                </Else>{' '}
              </If>{' '}
              <Switch>
                <Case
                  condition={this.global.dnd_status && this.global.callingFromWeb}
                >
                  {' '}
                  <Tooltip title={DND_ON}>
                    <div className="inline" onClick={this.showConfirmUnsetDND}>
                      Ne pas déranger <i> OFF </i>{' '}
                      <Badge
                        style={{
                          marginLeft: '15px',
                          fontSize: '10px',
                          top: '-1px',
                        }}
                        status="green"
                      />
                    </div>{' '}
                  </Tooltip>{' '}
                  <Divider type="vertical" />
                </Case>
                <Case
                  condition={
                    this.global.dnd_status &&
                    !this.global.callingFromWeb &&
                    this.global.lineStatus
                  }
                >
                  {' '}
                  <Tooltip title={DND_ON}>
                    <div className="inline" onClick={this.showConfirmUnsetDND}>
                      Ne pas déranger sur ligne <i> WEB </i>{' '}
                      <Badge
                        style={{
                          marginLeft: '15px',
                          fontSize: '10px',
                          top: '-1px',
                        }}
                        status="orange"
                      />
                    </div>{' '}
                  </Tooltip>{' '}
                  <Divider type="vertical" />
                </Case>
                <Default>
                  {' '}
                  <Tooltip title={DND_ON}>
                    <div className="inline" onClick={this.showConfirmUnsetDND}>
                      Ne pas déranger <i> OFF </i>{' '}
                      <Badge
                        style={{
                          marginLeft: '15px',
                          fontSize: '10px',
                          top: '-1px',
                        }}
                        status="green"
                      />
                    </div>{' '}
                  </Tooltip>{' '}
                  <Divider type="vertical" />
                </Default>
              </Switch>
              <Checkbox checked={this.global.entryCallRing} onChange={this.onChange}>
                Activer la sonnerie
              </Checkbox>
              <Divider type="vertical" />
              <Tooltip title="Logout">
                <React_Icons
                  size={'20px'}
                  icon={exit}
                  style={{
                    marginLeft: '10px',
                    marginBottom: '0px',
                  }}
                  onClick={() => this.handleLogout()}
                />{' '}
              </Tooltip>{' '}
            </div>{' '}
          </div>{' '}
        </Header>{' '}
      </div>
    )
  }
}

//export { TopbarComponent }

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
    dataConnectionServer: state.dataConnectionServer.dataConnectionServer,
  }
}

export default connect(mapStateToProps, {
  AddAccountUa,
  AddInfoServe,
})(TopbarComponent)
