import axios from 'axios'
// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT

// REQUEST FOR GET TOKEN AUTHENTIFICATION

const getTokenAutho = async (user) => {
  const reponse = await axios
    .post('https://' + SRV_ENDPOINT + ':8082/login', user, {
      headers: {
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + SRV_ENDPOINT + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return error
    })
  return reponse
}

// CHECK TOKEN AND IP FOR CONNEXION WEBAPP

const checkLocalStorage = async () => {
  if (JSON.parse(localStorage.getItem('user')) === null) {
    return null
  }
  const data = {
    token: JSON.parse(localStorage.getItem('user')).token,
    domainName: JSON.parse(localStorage.getItem('user')).infoServer.domain,
  }
  return data
}

// REQUEST FOR USERS INFORMATION

const getDataUser = async () => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(`https://` + domainName + `:8082/account`, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return null
    })

  return reponse
}

// REQUEST FOR USER VOICEMAILS
const getVoicemails = async () => {
  const { token, domainName } = await checkLocalStorage()
  const own_extension = JSON.parse(localStorage.getItem('user'))
  console.log(own_extension)
  if (token === null || domainName === null) return null
  const reponse = await axios
    .post(`https://` + domainName + `:3003/api/getAllVoicemails`, {
      extension:
        own_extension.userInfo.parentName + '#' + own_extension.userInfo.extension,
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return null
    })

  return reponse
}

// REQUEST FOR GET ALL CONTACT COMPAGNY USERS

const getAllUserCompany = async () => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(`https://` + domainName + `:8082/account/contact`, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const setDndOn = async () => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(`https://` + domainName + `:8082/dnd/set/on`, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const setDndOff = async () => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(`https://` + domainName + `:8082/dnd/set/off`, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const getVoicemailData = async () => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(`https://` + domainName + `:8082/voicemail`, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'audio/wav',
        'Content-Type': 'application/json audio/wav; charset=utf-8',
      },
    })
    .then((res) => {
      console.log(res)
      return res.data
    })
    .catch((error) => {
      console.log(error)
      return error
    })

  return reponse
}

const playVoicemailAudio = async (file) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(file)
    .then((res) => {
      console.log(res)
      return res
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const deleteVoiceMail = async (file) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .delete(`https://` + domainName + `:8082/voicemail/delete/` + file, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'audio/wav',
      },
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const getHistoryquesCall = async () => {
  const { token, domainName } = await checkLocalStorage()
  const own_extension = JSON.parse(localStorage.getItem('user'))
  if (token === null || domainName === null) return null
  const reponse = await axios
    .post(`https://` + domainName + `:3003/api/getCallLogsExtension`, {
      extension: parseFloat(own_extension.userInfo.extension),
    })
    .then((res) => {
      console.log(res.data)
      return res.data
    })
    .catch((error) => {
      return null
    })

  return reponse
}

const getCallRecentLegacy = async () => {
  const { token, domainName } = await checkLocalStorage()
  const own_extension = JSON.parse(localStorage.getItem('user'))
  const reponse = await axios
    .get(
      'https://' +
        domainName +
        ':8080/call/legacy/' +
        own_extension.userInfo.parentName +
        '/' +
        own_extension.userInfo.extension,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      console.log(res)
      return res.data
    })
    .catch((error) => {
      console.log(error)
      return error
    })

  return reponse
}

const getAllRedirection = async () => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .get(`https://` + domainName + `:8082/redirections`, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const deleteRedirectionBdd = async (idRedirection) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .delete(`https://` + domainName + `:8082/redirections/delete/` + idRedirection, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const addRedirection = async (dataRedirection) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .post(`https://` + domainName + `:8082/redirections/add`, dataRedirection, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const ModifyRedirection = async (dataRedirection) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .put(`https://` + domainName + `:8082/redirections/update`, dataRedirection, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })

  return reponse
}

const addContact = async (contactInfo) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .post(`https://` + domainName + `:8082/account/contact/add`, contactInfo, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return error
    })
  return reponse
}

const deleteContact = async (id) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .delete(`https://` + domainName + `:8082/account/contact/delete/` + id, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return error
    })
  return reponse
}

const deleteNumberContact = async (id) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .delete(`https://` + domainName + `:8082/account/contact/delete/number/` + id, {
      headers: {
        Authorization: `Bearer ` + token,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return error
    })
  return reponse
}

const modifyContact = async (contactInfo) => {
  const { token, domainName } = await checkLocalStorage()
  if (token === null || domainName === null) return null
  const reponse = await axios
    .put(`https://` + domainName + `:8082/account/contact/update`, contactInfo, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Strict-Transport-Security': '1000',
        'Access-Control-Allow-Origin': 'https://' + domainName + ':8082/',
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return error
    })
  return reponse
}

export {
  getDataUser,
  getAllUserCompany,
  getVoicemailData,
  playVoicemailAudio,
  getHistoryquesCall,
  getAllRedirection,
  deleteRedirectionBdd,
  addRedirection,
  ModifyRedirection,
  getTokenAutho,
  addContact,
  deleteContact,
  deleteNumberContact,
  modifyContact,
  deleteVoiceMail,
  checkLocalStorage,
  getVoicemails,
  getCallRecentLegacy,
}
