import { rem } from 'polished'
import React, { Component } from 'reactn'
import styled from 'styled-components'

const TouchKey = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 90px;
  min-width: 140px;
  width: 33.33%;
  font-size: 36px;
  max-width: 140px;
  border: ${rem(1)} solid #292929;
  cursor: pointer;

  @media only screen and (max-width: 350px) {
    min-width: inherit;
    height: ${rem(60)};
  }
`

class TouchKeyBoard extends Component {
  sendTouchParent = (e) => {
    console.log('sendTouchParent')
    console.log(e)
    //   this.props.onClick(e)
  }

  longPressStart = () => {
    console.log('Long press Started')
  }

  longPressEnd = () => {
    console.log('Long press Ends')
  }

  start(e) {
    console.log('START')
  }

  end = (e, enough) => {
    console.log(enough)

    if (!enough) {
      this.sendTouchParent(this.props.info.number)
    } else {
      console.log(this.props.info.number)
      let key = this.props.info.number
      if (key === 0) {
        console.log('in the IF')
        this.sendTouchParent('+')
      }
    }

    console.log('END')
    console.log(
      enough ? 'Click released after enough time' : 'Click released too soon'
    )
  }

  clickNHold(e) {
    console.log('CLICK AND HOLD')
  }

  render() {
    return (
      <TouchKey onClick={this.sendTouchParent} value={this.props.info.number}>
        <strong>{this.props.info.number}</strong>
        <br />
        <div style={{ fontSize: '10px' }}>{this.props.info.infoTouch}</div>
      </TouchKey>
    )
  }
}

export default TouchKeyBoard
