import axios from 'axios'
import React, { Component, setGlobal } from 'reactn'
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const options = {
  mediaConstraints: { audio: true, video: false },
  pcConfig: {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
    ],
  },
  rtcOfferConstraints: {
    offerToReceiveAudio: true,
    offerToReceiveVideo: false,
  },
  mandatory: [
    {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: false,
    },
    { DtlsSrtpKeyAgreement: true },
  ],
}

export async function makeCall(ua, number, e) {
  //e.preventDefault();
  if (JSON.parse(localStorage.getItem('user')) === null) {
    return null
  }

  const own_extension = JSON.parse(localStorage.getItem('user'))
  const domainName = JSON.parse(localStorage.getItem('user')).infoServer.domain

  console.log(own_extension)

  await axios
    .get(
      'https://' +
        SRV_ENDPOINT +
        ':8082/dnd/set/normal/on/' +
        own_extension.userInfo.extension
    )
    .then((res) => {
      console.log(res)
    })

  if (number && number != own_extension.userInfo.extension) {
    let numberClean = number.replace(/[.\s()-]/g, '')
    let numberConcat
    if (numberClean.length === 10) {
      numberConcat = '0'.concat(numberClean)
    } else if (numberClean.length >= 12) {
      let regex = new RegExp(/^[+]/, 'gm')
      if (regex.test(numberClean)) {
        let beginNumberIsDelete = numberClean.substring(1)
        numberConcat = '000'.concat(beginNumberIsDelete)
      }
    } else if (numberClean.length < 10) {
      numberConcat = numberClean
    } else {
      numberConcat = numberClean
    }
    let session = await ua.call('sip:' + numberConcat + '@' + domainName, options)
    let myCandidateTimeout

    session.connection.addEventListener('addstream', function (e) {
      var audio = document.createElement('audio')
      audio.srcObject = e.stream
      audio.play()
    })
    // Fix waiting for ICE Candidates to init outgoing call
    session.on('icecandidate', function (event) {
      if (
        event.candidate.type === 'srflx' &&
        event.candidate.relatedAddress !== null &&
        event.candidate.relatedPort !== null
      ) {
        event.ready()
      }
    })
    return session
  }
}
