import { Divider, Input, Spin, Tag } from 'antd'
import axios from 'axios'
import { Row } from 'react-bootstrap'
import React, { Component } from 'reactn'
import { checkLocalStorage, getDataUser } from '../../../Js/dataUserGeneral'
import ContactCardComponent from './ContactCardComponent'

const arraySort = require('array-sort')
// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT

const { Search } = Input
const { CheckableTag } = Tag
class ContactsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data_actual_user: [],
      data_lines_status: [],
      data_lines_searched: [],
      selectedTags: [],
      data_lines_status_unsorted: [],
      is_in_search: false,
    }
  }

  findByTag = async (value, checked) => {
    if (!checked) {
      await this.setState({
        tag_selected: false,
        is_in_search: false,
      })
      await this.setGlobal({
        data_lines_searched: this.global.data_lines_status,
      })
    } else {
      await this.setState({
        is_in_search: true,
      })
    }

    const { selectedTags } = this.state
    const nextSelectedTags = checked
      ? [...selectedTags, value]
      : selectedTags.filter((t) => t !== value)
    console.log('You are interested in: ', nextSelectedTags)
    await this.setState({
      selectedTags: nextSelectedTags,
    })

    var emptyArr = []
    const lines_dataset = this.global.data_lines_status
    //

    const result_group_name = lines_dataset.find((i) =>
      i['groupuser.name'].includes(value)
    )

    console.log(result_group_name)

    if (result_group_name) {
      emptyArr.push(result_group_name)
      this.setState({
        tag_selected: true,
      })
      this.setGlobal({
        data_lines_searched: emptyArr,
      })
    }
  }

  handleChange(value) {
    this.setState({
      is_in_search: true,
    })
    const emptyArr = []
    const lines_dataset = this.global.data_lines_status
    const nameCapitalized = value.charAt(0).toUpperCase() + value.slice(1)
    const result_name = lines_dataset.find((i) => i.name.includes(value))
    const result_name_capitalized = lines_dataset.find((i) =>
      i.name.includes(nameCapitalized)
    )
    const result_extension = lines_dataset.find((line) => line.extension === value)

    if (result_name_capitalized) {
      emptyArr.push(result_name_capitalized)
      this.setGlobal({
        data_lines_searched: emptyArr,
      })
    } else if (result_name) {
      emptyArr.push(result_name)
      this.setGlobal({
        data_lines_searched: emptyArr,
      })
    }

    if (result_extension) {
      emptyArr.push(result_extension)
      this.setGlobal({
        data_lines_searched: emptyArr,
      })
    }

    if (value === '') {
      this.setGlobal({
        data_lines_searched: lines_dataset,
      })
      this.setState({
        is_in_search: false,
      })
    }

    if (result_extension === 'undefined' && result_name === 'undefined') {
      this.setGlobal({
        data_lines_searched: lines_dataset,
      })
    }
  }

  sendMessage = async () => {
    const { ws } = this.global
    const own_extension = JSON.parse(localStorage.getItem('user'))
    const { token } = await checkLocalStorage()
    try {
      ws.send(
        JSON.stringify({
          token,
          message: 'getCoworkers',
          clientId: own_extension.userInfo.extension,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  fetch_user_lines = async () => {
    try {
      this.sendMessage()
      if (this.state.is_in_search) {
        console.log('In search no change data')
      } else {
        const data_now = this.global.data_lines_status
        this.setGlobal({
          data_lines_searched: data_now,
        })
      }
    } catch (error) {
      console.log(error)
      return error
    }
    this.intervalID = setTimeout(this.fetch_user_lines.bind(this), 1000)
  }

  async componentDidMount() {
    await this.fetch_user_lines()
    const actual_user = await getDataUser()
    // Fetch groups
    if (SRV_ENDPOINT === 'srv.petitefriture.nextcall.io') {
      await axios
        .get('https://' + SRV_ENDPOINT + ':3003/api/groups/1')
        .then((res) => {
          const groups = res.data
          this.setState({
            groups: groups.groups,
          })
        })
    } else {
      await axios
        .get('https://' + SRV_ENDPOINT + ':3003/api/groups/1')
        .then((res) => {
          const groups = res.data
          this.setState({
            groups: groups.groups,
          })
        })
    }

    if (actual_user !== null) {
      this.setState({
        data_actual_user: actual_user,
      })
    }
  }

  async componentWillUnmount() {
    clearTimeout(this.intervalID)
  }

  renderGroups = (data) => {
    const context_there = this
    const { selectedTags } = this.state
    if (data) {
      const groups_dataset = data
      const groups = []

      groups_dataset.forEach((item) => {
        groups.push(
          <div className="inline">
            <CheckableTag
              color="red"
              key={item.name}
              checked={selectedTags.indexOf(item.name) > -1}
              onChange={(checked) => context_there.findByTag(item.name, checked)}
            >
              {' '}
              {item.name}{' '}
            </CheckableTag>{' '}
          </div>
        )
      })
      return groups
    }
  }

  renderContacts = (data) => {
    if (data) {
      const lines_dataset = data
      const lines = []
      lines_dataset.slice(-250).forEach((item) => {
        //  console.log(item);
        lines.push(
          <div className="lines-inline-userpage">
            <ContactCardComponent contact={item} />{' '}
          </div>
        )
      })
      return lines
    }
    return (
      <div className="text-center">
        <Spin tip="Chargement..." />
      </div>
    )
  }

  render() {
    return (
      <div id="contacts">
        <Row>
          <div className="users-col-userspage">
            <div id="users">
              <h3
                style={{
                  textTransform: 'uppercase',
                }}
                className="inline"
              >
                Contacts{' '}
              </h3>{' '}
              <div
                id="search_contacts"
                className="inline"
                style={{
                  float: 'right',
                }}
              >
                <Search
                  placeholder="Recherche..."
                  onSearch={(value) => this.handleChange(value)}
                  style={{
                    width: 200,
                    marginRight: '25px',
                  }}
                />{' '}
              </div>
              <div
                style={{
                  marginTop: '0px',
                  marginBottom: '20px',
                }}
              >
                <div
                  style={{
                    color: 'gainsboro',
                    marginBottom: '10px',
                  }}
                >
                  Filtrer par groupes{' '}
                </div>
                {this.renderGroups(this.state.groups)}{' '}
              </div>
              <Divider />
              <div id="content_contacts">
                {' '}
                {this.renderContacts(this.global.data_lines_searched)}{' '}
              </div>{' '}
            </div>{' '}
          </div>{' '}
        </Row>{' '}
      </div>
    )
  }
}

export default ContactsPage
