const initialState = {
  navBarEtat: {
    show: false,
  },
}

function changeEtatNavBar(state = initialState, action) {
  let nextState

  switch (action.type) {
    case 'NAV_BAR_ETAT_CHANGE':
      nextState = {
        ...state,
        navBarEtat: {
          show: action.value.show,
        },
      }
      return nextState || state
    default:
      return state
  }
}

export default changeEtatNavBar
