import React, { Component, setGlobal } from 'reactn'

import KeyBoard from './KeyBoard'
import icoKeyBoard from '../../../assets/icone/dialpad.png'
import { rem } from 'polished'
import styled from 'styled-components'

const ContenaireAll = styled.div``

const BackgroundAnimated = styled.em`
  height: 75px;
  width: 75px;
  position: fixed;
  bottom: ${rem(60)};
  right: ${rem(60)};
  background-color: #292929;
  border-radius: 100%;

  -webkit-animation: scale-up-center 0.4s;
  animation: scale-up-center 0.4s;

  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    30% {
      box-shadow: inset 0 0 0px 10px $red, inset 0 0 0px 30px white;
    }
    100% {
      box-shadow: inset 0 0 0px 0px $red, inset 0 0 0px 0px white;
    }
  }

  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 800px) {
    bottom: ${rem(40)};
    right: ${rem(40.5)};
  }
`

const Backdrop = styled('div')`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000085;
  opacity: 1;
`

const ContenaireButton = styled.button`
  background-color: #616161;
  border-radius: 100%;
  height: 65px;
  position: fixed;
  bottom: ${rem(65)};
  right: ${rem(65)};
  border: none;
  cursor: pointer;

  -webkit-animation: scale-up-center 0.4s;
  animation: scale-up-center 0.4s;

  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    30% {
      box-shadow: inset 0 0 0px 10px $red, inset 0 0 0px 30px white;
    }
    100% {
      box-shadow: inset 0 0 0px 0px $red, inset 0 0 0px 0px white;
    }
  }

  @media only screen and (max-width: 800px) {
    bottom: ${rem(45)};
    right: ${rem(45)};
  }
`

class ButtonKeyBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false,
      activeButton: false,
    }
  }

  render() {
    return (
      <div id="container-keyboard">
        {this.global.opened && (
          <div>
            <Backdrop />
            <KeyBoard />
          </div>
        )}
      </div>
    )
  }
}

export default ButtonKeyBoard
