import '../App.scss'

import { Else, If, Then } from 'react-if'
import { Layout, Menu } from 'antd'
import React, { Component, setGlobal } from 'reactn'
import {
  faAddressBook,
  faHistory,
  faHome,
  faPhone,
  faUsers,
  faVoicemail,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KeyBoard from '../components/Page/ButtonKeyBoard/KeyBoard'
import { ReactComponent as Logo } from '../assets/Logo_white.svg'
import { history } from '../action/history'

const { Sider } = Layout
const { SubMenu } = Menu
class MenuComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: null,
      selectedKey: ['1'],
      defaultSelectedKeys: ['1'],
      defaultOpenKeys: ['1'],
    }
    this.openDialPad = this.openDialPad.bind(this)
  }

  async go_to_dashboard() {
    var s = []
    s.push('1')
    await this.setState({ defaultSelectedKeys: s })
    history.push('/dashboard')
  }

  async go_to_contacts() {
    var s = []
    s.push('2')
    await this.setState({ defaultSelectedKeys: s })
    history.push('/contacts')
  }

  async go_to_users() {
    var s = []
    s.push('3')
    await this.setState({ defaultSelectedKeys: s })
    history.push('/users')
  }

  async go_to_voicemail() {
    var s = []
    s.push('4')
    await this.setState({ defaultSelectedKeys: s })
    history.push('/voicemail')
  }

  async go_to_history() {
    var s = []
    s.push('0')
    await this.setState({ defaultSelectedKeys: s })
    history.push('/history')
  }

  async componentWillMount() {
    await this.setState({ location: window.location.pathname })
    var s = []
    var s2 = []

    if (window.location.pathname === '/dashboard') {
      s.push('1')
      await this.setState({ defaultSelectedKeys: s })
    }

    if (window.location.pathname === '/contacts') {
      s.push('2')
      await this.setState({ defaultSelectedKeys: s })
    }

    if (window.location.pathname === '/users') {
      s.push('3')
      await this.setState({ defaultSelectedKeys: s })
    }

    if (window.location.pathname === '/voicemail') {
      s.push('4')
      await this.setState({ defaultSelectedKeys: s })
    }

    if (window.location.pathname === '/history') {
      s.push('0')
      await this.setState({ defaultSelectedKeys: 0 })
    }
  }

  openDialPad() {
    console.log('Open dialPad')
    const { opened, activeButton } = this.global
    this.setGlobal({
      opened: !opened,
      activeButton: !activeButton,
    })
  }

  render() {
    const { location } = window.location.pathname
    return (
      <div>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            minHeight: '100rem',
            maxHeight: '100%',
            paddingLeft: '10px',
            position: 'fixed',
            left: 0,
            width: '230px',
            maxWidth: '230px',
          }}
        >
          <div
            className="logo"
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingLeft: '10px',
              backgroundColor: '#121212',
            }}
            onClick={() => this.go_to_dashboard('dashboard')}
          >
            <Logo style={{ width: '95%' }} />
          </div>
          <div style={{ marginBottom: '35px' }}>
            <If condition={window.location.pathname === '/history'}>
              <Then>
                <div
                  className="menu-item-square-top margin-7-left"
                  onClick={() => this.go_to_history('history')}
                >
                  <FontAwesomeIcon
                    icon={faHistory}
                    className="icon-menu-item-square-top white"
                  />
                </div>
              </Then>
              <Else>
                <div
                  className="menu-item-square-top margin-7-left"
                  onClick={() => this.go_to_history('history')}
                >
                  <FontAwesomeIcon
                    icon={faHistory}
                    className="icon-menu-item-square-top"
                  />
                </div>
              </Else>
            </If>

            <div className="menu-item-square-top" onClick={() => this.openDialPad()}>
              <FontAwesomeIcon
                icon={faPhone}
                className="icon-menu-item-square-top"
              />
            </div>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={this.state.defaultSelectedKeys}
          >
            <Menu.Item key="1" onClick={() => this.go_to_dashboard('dashboard')}>
              <FontAwesomeIcon
                icon={faHome}
                style={{ color: '#149995', marginRight: '15px' }}
              />
              <span className="nav-text">Accueil</span>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => this.go_to_contacts('contacts')}>
              <FontAwesomeIcon
                icon={faAddressBook}
                style={{ color: '#149995', marginRight: '19.5px' }}
              />
              <span className="nav-text">Contacts</span>
            </Menu.Item>
            <Menu.Item key="3" onClick={() => this.go_to_users('users')}>
              <FontAwesomeIcon
                icon={faUsers}
                style={{ color: '#149995', marginRight: '13px' }}
              />
              <span className="nav-text">Utilisateurs</span>
            </Menu.Item>
            <Menu.Item key="4" onClick={() => this.go_to_voicemail('voicemail')}>
              <FontAwesomeIcon
                icon={faVoicemail}
                style={{ color: '#149995', marginRight: '13px' }}
              />
              <span className="nav-text">Répondeur</span>
            </Menu.Item>
          </Menu>
        </Sider>
      </div>
    )
  }
}

export { MenuComponent }
