import { Case, Default, Switch } from 'react-if'
import { Divider, Tooltip } from 'antd'
import React, { Component, setGlobal } from 'reactn'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { makeCall } from './MakeCall'

const TOOLTIP_CALL_THIS = 'Click to call.'

class ContactCardComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps() {}

  makeCall = async (element) => {
    const { opened, activeButton } = this.global
    await this.setGlobal({
      opened: !opened,
      activeButton: !activeButton,
    })
    this.setGlobal({
      callingFromWeb: true,
    })
    await makeCall(this.props.ua, element.extension, 'Call to')
  }

  renderEmail = (data) => {
    const context_there = this
    if (data) {
      var mail_div = []
      mail_div.push(
        <p style={{ display: 'inline-block' }}>{this.props.contact.mail}</p>
      )
    } else {
      var mail_div = []
      mail_div.push(
        <p style={{ display: 'inline-block' }}>
          <i>No email</i>
        </p>
      )
    }
    return mail_div
  }

  renderDirectLine = (data) => {
    const context_there = this
    var myProp = 'directLines'
    if (data && data !== 'undefined') {
      if (data.directLines !== 'undefined') {
        if (data.directLines != null) {
          console.log(data)
          var dl_div = []
          dl_div.push(
            <div
              style={{
                display: 'inline-block',
                float: 'right',
                fontWeight: '600',
              }}
            >
              {data.directLines[0].directLine}
            </div>
          )
        }
      }
    } else {
      var dl_div = []
      dl_div.push(<p></p>)
    }
    return dl_div
  }

  render() {
    const {} = this.state
    const context_there = this
    return (
      <div>
        <Switch>
          <Case
            condition={
              this.props.contact.status === 'Not in use' ||
              this.props.contact.status === 'Not In Use'
            }
          >
            <div className="contact-card">
              <div className="contact-card-name">{this.props.contact.name}</div>
              <Tooltip title={TOOLTIP_CALL_THIS}>
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: '5px',
                    float: 'right',
                    cursor: 'pointer',
                  }}
                  onClick={() => context_there.makeCall(this.props.contact)}
                >
                  {this.props.contact.extension}
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="lines-icon-green-contact-card"
                  />
                </div>
              </Tooltip>
              <Divider className="small-divider" />
              {this.renderEmail(this.props.contact.mail)}
              {this.renderDirectLine(this.props.contact.directLines)}
            </div>
          </Case>
          <Case
            condition={
              this.props.contact.status === 'In Use' ||
              this.props.contact.status === 'In use'
            }
          >
            <div className="contact-card">
              <div style={{ display: 'inline-block' }}>
                {this.props.contact.name}
              </div>
              <div
                style={{
                  display: 'inline-block',
                  marginRight: '5px',
                  float: 'right',
                }}
              >
                {this.props.contact.extension}
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="lines-icon-orange-contact-card"
                />
              </div>
              <Divider className="small-divider" />
              {this.renderEmail(this.props.contact.mail)}
            </div>
          </Case>
          <Case
            condition={
              this.props.contact.status === 'Unavailable' ||
              this.props.contact.status === 'unavailable'
            }
          >
            <div className="contact-card">
              <div style={{ display: 'inline-block' }}>
                {this.props.contact.name}
              </div>
              <div
                style={{
                  display: 'inline-block',
                  marginRight: '5px',
                  float: 'right',
                }}
              >
                {this.props.contact.extension}
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="lines-icon-red-contact-card"
                />
              </div>
              <Divider className="small-divider" />
              {this.renderEmail(this.props.contact.mail)}
            </div>
          </Case>
          <Default>
            <div></div>
          </Default>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
  }
}

export default connect(mapStateToProps)(ContactCardComponent)
