import React, { Component, setGlobal } from 'reactn'
import styled, { keyframes } from 'styled-components'

import { ReactComponent as AcceptSVG } from '../../../assets/call_icon.svg'
import { ReactComponent as DeniedSVG } from '../../../assets/decline-call_icon.svg'
import { Icon } from 'react-icons-kit'
import { ReactComponent as Logo } from '../../../assets/Logo_white.svg'
import { ReactMotionLoop } from 'react-motion-loop'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import { cross } from 'react-icons-kit/icomoon/cross'
// eslint-disable-next-line no-unused-vars
import logoNC from '../../../assets/icone/IconLogo_NextCall.png'
import { pulse } from 'react-animations'
import { rem } from 'polished'
import { spring } from 'react-motion'
import Ring03a from '../../../assets/Ring03a.mp3'
import UIfx from 'uifx'
import axios from 'axios'

const arraySort = require('array-sort')
// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT
const HEAD_TITLE = 'NextCall Softphone'

const Ring = new UIfx(Ring03a, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const bounceAnimation = keyframes`${pulse}`
const ContenairAcceptOrDecline = styled.div`
  position: block;
  background-color: #292929;
  z-index: 3;
  width: 420px;
  margin-top: -0px;

  p {
    font-size: 20px;
    font-weight: bold;
    color: #fafafa;
    text-align: center;
  }
`

const AcceptCall = styled.button`
  color: #49aa19;
  background: #1e1e1e;
  border: 0.0625rem solid #292929;
  height: 70px;
  width: 50%;
  font-size: 1em;
`

const DeclineCall = styled.button`
  color: #d32029;
  background: #1e1e1e;
  border: 0.0625rem solid #292929;
  width: 50%;
  height: 70px;

  font-size: 1em;
`

class AcceptOrDecline extends Component {
  componentWillMount = async (e) => {
    console.log('Accept or decline component mounted')
    await this.setGlobal({
      opened: true,
      while_incoming_call: true,
      missed_call: true,
    })
    this.intervalID_ring = setInterval(this.timerRing.bind(this), 2700)
  }

  componentWillUnmount = async () => {
    console.log('Accept AcceptOrDecline componentWillUnmount')
    clearTimeout(this.intervalID_ring)
    if (this.global.missed_call) {
      this.NotifyMissedCall()
      await this.setGlobal({ missed_call: false })
    }
  }

  timerRing = (e) => {
    console.log('TimerRing')
    if (this.global.while_incoming_call && this.global.entryCallRing) {
      Ring.play()
    } else {
      console.log('User no want ring')
    }
  }

  acceptCall = async (e) => {
    this.props.onClick(e.target.value)
    const own_extension = JSON.parse(localStorage.getItem('user'))

    await axios
      .get(
        'https://' +
          SRV_ENDPOINT +
          ':8082/dnd/normal/on/' +
          own_extension.userInfo.extension
      )
      .then((res) => {
        console.log(res)
      })

    if (this.props.infoSession) {
      await this.props.infoSession.answer(this.props.infoOption.optionCalls)
    }
    await this.setGlobal({
      while_incoming_call: false,
      missed_call: false,
    })
  }

  declineCall = async (e) => {
    console.log('Decline call')
    console.log(this.props.infoSession)
    this.props.onClick(e.target.value)
    this.props.infoSession.terminate()
    console.log(this.props.infoSession)
    // this.props.infoSession.terminate();
    if (this.props.infoCall) {
      console.log(this.props.infoCall)
    }
    // session.terminate();
    await this.setGlobal({
      number: null,
      in_communication: false,
      while_incoming_call: false,
      missed_call: false,
    })
  }

  NotifyMe = () => {
    if (!('Notification' in window)) {
      alert('This browser does not support notification .')
    } else if (Notification.permission === 'granted') {
      // eslint-disable-next-line react/prop-types
      const title = `Appel de ${this.props.infoSession._remote_identity._display_name} ${this.props.infoSession._remote_identity._uri._user}`
      const option = {
        icon:
          'https://nextcall-files-1.s3.eu-west-3.amazonaws.com/IconLogo_NextCall.png',
      }
      const notification = new Notification(title, option)
      notification.onclick = function (event) {
        event.preventDefault() // empêcher le navigateur de passer le focus sur l'onglet de la navigation
        window.open('https://' + WEB_ENDPOINT + '/', '_blank')
      }
      setTimeout(notification.close.bind(notification), 25000)
    }
  }

  NotifyMissedCall = () => {
    if (!('Notification' in window)) {
      alert('This browser does not support notification .')
    } else if (Notification.permission === 'granted') {
      // eslint-disable-next-line react/prop-types
      const title = `Appel en absence de ${this.props.infoSession._remote_identity._display_name} ${this.props.infoSession._remote_identity._uri._user}`
      const option = {
        icon:
          'https://nextcall-files-1.s3.eu-west-3.amazonaws.com/IconLogo_NextCall.png',
      }
      const notification = new Notification(title, option)
      notification.onclick = function (event) {
        event.preventDefault() // empêcher le navigateur de passer le focus sur l'onglet de la navigation
        window.open('https://' + WEB_ENDPOINT + '/', '_blank')
      }
      setTimeout(notification.close.bind(notification), 25000)
    }
  }

  render() {
    this.NotifyMe()
    return (
      <ContenairAcceptOrDecline>
        <div style={{ color: '#149995', fontSize: '50px', fontWeight: 'bold' }}>
          {this.props.infoSession._remote_identity._uri._user}
        </div>
        <p>{this.props.infoSession._remote_identity._display_name}</p>
        <AcceptCall onClick={this.acceptCall} value>
          <ReactMotionLoop
            styleFrom={{ width: 120, height: 40 }} // Instantly jump to the initial style
            styleTo={{ width: spring(120), height: spring(50) }}
          >
            {(style) => <AcceptSVG style={style} />}
          </ReactMotionLoop>
        </AcceptCall>
        <DeclineCall onClick={this.declineCall} value={false}>
          <ReactMotionLoop
            styleFrom={{ width: 120, height: 40 }} // Instantly jump to the initial style
            styleTo={{ width: spring(120), height: spring(50) }}
          >
            {(style) => <DeniedSVG style={style} />}
          </ReactMotionLoop>
        </DeclineCall>
      </ContenairAcceptOrDecline>
    )
  }
}

export default AcceptOrDecline
