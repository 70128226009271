/*import JsSIP from 'jssip';
import PopUpCall from '../components/Page/PopUpCall/PopUpCall'

var socket = new JsSIP.WebSocketInterface('wss://dev.srv.asii.fr:8089/ws');
socket.via_transport = "udp";
var configuration = {
  sockets  : [ socket ],
  uri      : 'sip:ASII%23545@dev.srv.asii.fr:5161',
  password : ':6UMA5iF0b',
  iceServers: ['stun:stun.l.google.com:19302']
};

var ua = new JsSIP.UA(configuration);

console.log("ua ?", ua);

ua.start();
*/
const optionCalls = async () => {
  var eventHandlers = {
    progress: function (e) {
      console.log('call is in progress')
    },
    failed: function (e) {
      console.log('call failed with cause: ')
      console.lof(e)
    },
    ended: function (e) {
      console.log('call ended with cause: ')
      console.log(e)
    },
    accepted: function (e) {
      console.log('call is accepted')
    },
    confirmed: function (e) {
      console.log('call confirmed')
    },
  }

  var options = {
    eventHandlers: eventHandlers,
    mediaConstraints: { audio: true, video: false },
    pcConfig: {
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
      ],
    },
    rtcOfferConstraints: { offerToReceiveAudio: true, offerToReceiveVideo: false },
    mandatory: [
      {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: false,
      },
      { DtlsSrtpKeyAgreement: true },
    ],
  }

  return options
}

export { optionCalls }
