const route = {
  LOGIN: '/login',
  HOME: '/dashboard',
  CONTACTS: '/contacts',
  CONTACTS_ADD: '/contacts/add',
  CONTACTS_MODIFY: '/contacts/modify',
  HISTORY: '/history',
  VOICEMAIL: '/voicemail',
  USERS: '/users',
}

export default route
