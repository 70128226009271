import { ADD_INFO_SERVE } from '../../components/utils/InfoServeConstant'

const initialState = {
  dataConnectionServer: null,
  loggedIn: true,
}

function dataServer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case ADD_INFO_SERVE:
      nextState = {
        ...state,
        loggingIn: true,
        dataConnectionServer: action.serve,
      }
      return nextState || state
    //case "MODIFY_DATA_CONNECTION_SERVER":
    //case "DELETE_DATA_CONNECTION_SERVER":
    default:
      return state
  }
}

export default dataServer
