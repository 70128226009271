import './index.css'

import * as serviceWorker from './serviceWorker'

import App from './App'
import { Provider } from 'react-redux'
import React from 'reactn'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import Store from './Store/configurationStore'
import { history } from '../src/action/history'

//import createHistory from 'history/createBrowserHistory';
//const history = createHistory();
ReactDOM.render(
  <Router history={history}>
    <Provider store={Store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
