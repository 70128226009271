import React, { Component, setGlobal } from 'reactn'
import {
  addContact,
  deleteNumberContact,
  modifyContact,
} from '../../../Js/dataUserGeneral'
import {
  faBuilding,
  faEnvelope,
  faExclamation,
  faMapMarkerAlt,
  faMinus,
  faPhoneAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { rem } from 'polished'
import styled from 'styled-components'

const AddContactContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 12px;
  margin-top: ${rem(5)};
`

const FormAddContact = styled.form`
  display: flex;
  flex-direction: column;
  margin: ${rem(25)} 0 ${rem(25)} 0;

  p {
    margin-top: 0;
    color: red;
  }
`

const LabelForm = styled.label`
  margin-bottom: ${rem(10)};
`

const ContenairInput = styled.label`
  display: flex;
  span {
    position: relative;
    display: inline-block;
    border-bottom: 2px solid #c4c4c4;
    outline: none;
    line-height: 20px;
    width: ${rem(35)};
    height: ${rem(34)};
  }

  em {
    position: relative;
    outline: none;
    line-height: 20px;
    cursor: pointer;
  }

  .fa-user {
    position: absolute;
    top: 9px;
    right: 12px;
  }

  .fa-phone-alt {
    position: absolute;
    top: 9px;
    right: 12px;
  }

  .fa-envelope {
    position: absolute;
    top: 9px;
    right: 12px;
  }

  .fa-map-marker-alt {
    position: absolute;
    top: 9px;
    right: 12px;
  }

  .fa-building {
    position: absolute;
    top: 9px;
    right: 12px;
  }

  .fa-minus {
    position: absolute;
    top: 9px;
  }
`

const InputConnection = styled.input`
    width: ${rem(240)};
    height: ${rem(32)};
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid #C4C4C4;
    outline: none
    font-size: 1em;
    margin-left: ${rem(20)};
    margin-bottom: ${rem(20)};
`

const ButtonAddNumber = styled.button`
    height: ${rem(35)}
    border: none;
    border-radius: 4px;
    background-color: #F3B733;
    margin-bottom: ${rem(20)};
    font-size: 1em;
    cursor: pointer;
`

const SelectNumberType = styled.select`
  margin-bottom: ${rem(20)};
  width: ${rem(240)};
  height: ${rem(30)};
  font-size: 1em;
  width: 100%;
`

const ButtonAddContact = styled.button`
    height: ${rem(35)}
    border: none;
    border-radius: 4px;
    background-color: #F3B733;
    margin-bottom: ${rem(20)};
    font-size: 1em;
    cursor: pointer;
`

class AddContactPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      numbers: [
        {
          number: '',
          type: 'work',
        },
      ],
      idContact: 0,
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      company: '',
      companyAdress: '',

      errorFirstName: null,
      errorLastName: null,
      errorNumbers: null,
      dataIsSend: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount = async () => {
    if (this.props.pathname === 'modify') {
      let dataContact
      if (typeof this.props.infoContact !== 'undefined') {
        localStorage.setItem(
          'infoContact',
          JSON.stringify(this.props.infoContact.infoContact)
        )
        dataContact = this.props.infoContact.infoContact
      } else {
        dataContact = JSON.parse(localStorage.getItem('infoContact'))
      }
      this.setState({
        idContact: dataContact.id,
        firstName: dataContact.first_name,
        lastName: dataContact.last_name,
        email: dataContact.email,
        address: dataContact.address,
        company: dataContact.company,
        companyAdress: dataContact.company_address,
        numbers: Object.keys(dataContact.numbers).map((item, i) => {
          return {
            id: dataContact.numbers[i].id,
            number: dataContact.numbers[i].number,
            type: dataContact.numbers[i].type,
          }
        }),
      })
    }
  }

  componentWillUnmount = async () => {
    localStorage.removeItem('infoContact')
  }

  errorDataInform = () => {
    const { firstName, lastName, numbers } = this.state
    this.setState({
      errorFirstName:
        firstName === '' ? (
          <p>
            <FontAwesomeIcon icon={faExclamation} color="red"></FontAwesomeIcon>{' '}
            Veuillez entrer un nom
          </p>
        ) : (
          ''
        ),
      errorLastName:
        lastName === '' ? (
          <p>
            <FontAwesomeIcon icon={faExclamation} color="red"></FontAwesomeIcon>{' '}
            Veuillez entrer un prénom
          </p>
        ) : (
          ''
        ),
      errorNumbers:
        numbers[0].number === '' ? (
          <p>
            <FontAwesomeIcon icon={faExclamation} color="red"></FontAwesomeIcon>{' '}
            Veuillez entrer au moins un numéro
          </p>
        ) : (
          ''
        ),
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const {
      firstName,
      idContact,
      lastName,
      email,
      address,
      company,
      companyAdress,
    } = this.state
    if (
      (this.state.numbers[0].number !== '' && firstName !== '') ||
      (this.state.numbers[0].number !== '' && lastName !== '')
    ) {
      let contact = {
        id: this.props.pathname === 'modify' ? idContact : '',
        firstName,
        lastName,
        email,
        address,
        company,
        companyAdress,
      }
      if (this.props.pathname === 'add') {
        let numbers = this.state.numbers
        await addContact({ contact, numbers })
      } else {
        let add = this.state.numbers.filter((user) => {
          if (user.hasOwnProperty('id') === false) {
            return user
          }
        })
        let update = this.state.numbers.filter((user) => {
          if (user.hasOwnProperty('id') === true) {
            return user
          }
        })
        await modifyContact({ contact, add, update })
      }
      this.setState({
        errorFirstName: '',
        errorLastName: '',
        errorNumbers: '',
        dataIsSend: true,
      })
    } else {
      this.errorDataInform()
    }
  }

  handleInputChange = async (e) => {
    e.preventDefault(e)
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleNumberInputChange = async (id, e) => {
    e.preventDefault(e)
    const newNumbersArray = this.state.numbers.map((numbers, sidx) => {
      if (id !== sidx) {
        return numbers
      }
      if (e.target.name === 'type') {
        let saveNumber = numbers.number
        return { ...numbers, number: saveNumber, type: e.target.value }
      } else {
        let saveType = numbers.type
        return { ...numbers, number: e.target.value, type: saveType }
      }
    })
    this.setState({
      numbers: newNumbersArray,
    })
  }

  deleteNumber = async (id, numbers, e) => {
    e.preventDefault()
    if (this.props.pathname === 'modify') {
      if (typeof numbers.id !== 'undefined') await deleteNumberContact(numbers.id)
    }
    const newArrayData = [...this.state.numbers]
    let numberArrayClean = newArrayData.filter((s, idx) => idx !== id)
    this.setState({
      numbers: numberArrayClean,
    })
  }

  addNumber = (e) => {
    e.preventDefault()
    this.setState({
      numbers: [
        ...this.state.numbers,
        {
          number: '',
          type: 'work',
        },
      ],
    })
  }

  render() {
    return (
      <AddContactContainer>
        <FormAddContact onSubmit={this.handleSubmit.bind(this)}>
          <LabelForm>Nom:</LabelForm>
          <ContenairInput>
            <InputConnection
              type="text"
              name="firstName"
              placeholder="Nom"
              value={this.state.firstName}
              onChange={this.handleInputChange}
            ></InputConnection>
            <span>
              <FontAwesomeIcon icon={faUser} color="#F3B733" />
            </span>
          </ContenairInput>
          {this.state.errorFirstName}
          <LabelForm>Prénom:</LabelForm>
          <ContenairInput>
            <InputConnection
              type="text"
              name="lastName"
              placeholder="Prénom"
              value={this.state.lastName}
              onChange={this.handleInputChange}
            ></InputConnection>
            <span>
              <FontAwesomeIcon icon={faUser} color="#F3B733" />
            </span>
          </ContenairInput>
          {this.state.errorLastName}
          <LabelForm>Numéro:</LabelForm>
          {this.state.numbers.map((numbers, idx) => (
            <div key={idx}>
              <ContenairInput>
                <InputConnection
                  type="text"
                  name="number"
                  placeholder="Numéro"
                  value={numbers.number}
                  onChange={this.handleNumberInputChange.bind(this, idx)}
                ></InputConnection>
                <span>
                  <FontAwesomeIcon icon={faPhoneAlt} color="#F3B733" />
                </span>
                {idx > 0 ? (
                  <em onClick={this.deleteNumber.bind(this, idx, numbers)}>
                    <FontAwesomeIcon icon={faMinus} color="#F3B733" />
                  </em>
                ) : null}
              </ContenairInput>
              <SelectNumberType
                name="type"
                checked={numbers.type}
                value={numbers.type}
                onChange={this.handleNumberInputChange.bind(this, idx)}
              >
                <option value="work">Travail</option>
                <option value="perso">Personnel</option>
              </SelectNumberType>
            </div>
          ))}
          {this.state.errorNumbers}
          <ButtonAddNumber type="button" onClick={this.addNumber.bind(this)}>
            Ajouter un numéro
          </ButtonAddNumber>
          <LabelForm>Email:</LabelForm>
          <ContenairInput>
            <InputConnection
              type="text"
              name="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
            ></InputConnection>
            <span>
              <FontAwesomeIcon icon={faEnvelope} color="#F3B733" />
            </span>
          </ContenairInput>
          <LabelForm>Adresse:</LabelForm>
          <ContenairInput>
            <InputConnection
              type="text"
              name="address"
              placeholder="Adresse"
              value={this.state.address}
              onChange={this.handleInputChange}
            ></InputConnection>
            <span>
              <FontAwesomeIcon icon={faMapMarkerAlt} color="#F3B733" />
            </span>
          </ContenairInput>
          <LabelForm>Société:</LabelForm>
          <ContenairInput>
            <InputConnection
              type="text"
              name="company"
              placeholder="Société"
              value={this.state.company}
              onChange={this.handleInputChange}
            ></InputConnection>
            <span>
              <FontAwesomeIcon icon={faBuilding} color="#F3B733" />
            </span>
          </ContenairInput>
          <LabelForm>Adresse De La Société:</LabelForm>
          <ContenairInput>
            <InputConnection
              type="text"
              name="companyAdress"
              placeholder="Adresse De La Société"
              value={this.state.companyAdress}
              onChange={this.handleInputChange}
            ></InputConnection>
            <span>
              <FontAwesomeIcon icon={faMapMarkerAlt} color="#F3B733" />
            </span>
          </ContenairInput>
          <ButtonAddContact type="submit">Ajouter le contact</ButtonAddContact>
        </FormAddContact>
        {this.state.dataIsSend ? <Redirect to="/contacts" /> : null}
      </AddContactContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
    dataConnectionServer: state.dataConnectionServer.dataConnectionServer,
  }
}

export default connect(mapStateToProps)(AddContactPage)
