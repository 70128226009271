import { Col, Row } from 'react-bootstrap'
import { Divider, Spin, Tooltip } from 'antd'
import React, { Component } from 'reactn'
import { faAsterisk, faFlask } from '@fortawesome/free-solid-svg-icons'
import { getAllUserCompany, getDataUser } from '../../../Js/dataUserGeneral'
import { checkLocalStorage } from '../../../Js/dataUserGeneral'
import axios from 'axios'
import ContactLineComponent from './ContactLineComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getValueDnd } from '../../../Js/dnd'
import { v4 as uuidv4 } from 'uuid'
const ERROR_NOTIFICATION_DESC = process.env.REACT_APP_ERROR_NOTIFICATION_DESC
const REFRESH_DATA = process.env.REACT_APP_REFRESH_DATA
const REFRESH_DATA_DESC = process.env.REACT_APP_REFRESH_DATA_DESC

// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT
var arraySort = require('array-sort')

class HomePage extends Component {
  intervalID
  constructor(props) {
    super(props)
    this.state = {
      ws: null,
      data_actual_user: [],
      data_lines_status: [],
      data_lines_searched: [],
      selectedTags: [],
      data_lines_status_unsorted: [],
      data_actual_user: [],
      data_lines_status: [],
      data_lines_status_unsorted: [],
      is_in_search: false,
    }
  }

  componentWillMount = async () => {
    console.log('component will unmount')
  }

  componentDidMount = async () => {
    await this.fetch_user_lines()
    const actual_user = await getDataUser()
    if (SRV_ENDPOINT === 'srv.petitefriture.nextcall.io') {
      await axios
        .get('https://' + SRV_ENDPOINT + ':3003/api/groups/1')
        .then((res) => {
          const groups = res.data
          this.setState({
            groups: groups.groups,
          })
        })
    } else {
      await axios
        .get('https://' + SRV_ENDPOINT + ':3003/api/groups/1')
        .then((res) => {
          const groups = res.data
          this.setState({
            groups: groups.groups,
          })
        })
    }
    // Fetch groups

    if (actual_user !== null) {
      this.setState({
        data_actual_user: actual_user,
      })
    }
    // await this.fetch_user_lines()
  }

  handleData = async () => {}
  connect = async () => {
    const own_extension = JSON.parse(localStorage.getItem('user'))

    let ws
    if (SRV_ENDPOINT === 'srv.petitefriture.nextcall.io') {
      ws = new WebSocket(
        //"ws:/localhost:3004/" + localStorage.getItem('uuid')
        'wss:/' + WSS_ENDPOINT + ':3003/' + own_extension.userInfo.extension
      )
    } else {
      ws = new WebSocket(
        //"ws:/localhost:3004/" + localStorage.getItem('uuid')
        'wss:/' + WSS_ENDPOINT + ':3003/' + own_extension.userInfo.extension
      )
    }
    let that = this
    var connectInterval

    ws.onopen = async () => {
      console.log('CONNECTED TO WEBSOCKET')
      await this.setGlobal({
        ws: ws,
      })
      await this.setState({
        ws_co: true,
      })
      that.timeout = 1500
      clearTimeout(connectInterval)
    }

    ws.onmessage = async (e) => {
      const data = JSON.parse(e.data)
      if (data !== null) {
        this.setGlobal({
          data_lines_status: arraySort(data, 'status'),
          data_lines_status_unsorted: data,
        })
      }
    }

    ws.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      )
      this.setState({
        ws_co: false,
      })
      that.timeout = that.timeout + that.timeout
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout))
    }

    ws.onerror = (err) => {
      console.error('Socket encountered error: ', err.message, 'Closing socket')
      this.setState({
        ws_co: false,
      })
      ws.close()
    }
  }
  sendMessage = async () => {
    const ws = this.global.ws
    const own_extension = JSON.parse(localStorage.getItem('user'))
    const { token } = await checkLocalStorage()
    try {
      ws.send(
        JSON.stringify({
          token: token,
          message: 'getCoworkers',
          clientId: own_extension.userInfo.extension,
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  fetch_user_lines = async () => {
    try {
      const actual_user = await getDataUser()
      await this.connect()
      await this.sendMessage()
      if (this.state.is_in_search) {
        console.log('In search no change data')
      } else {
        const data_now = this.global.data_lines_status
        this.setGlobal({
          data_lines_searched: data_now,
        })
      }
      if (actual_user !== null) {
        this.setGlobal({
          data_actual_user: actual_user,
        })
        this.setState({
          data_actual_user: actual_user,
        })
      }
    } catch (error) {
      console.log(error)
      return error
    }
    this.intervalID = setTimeout(this.fetch_user_lines.bind(this), 1000)
  }

  renderContactLines = (data) => {
    const context_there = this
    if (data) {
      const lines_dataset = data
      var lines = []
      lines_dataset.slice(-10).forEach(function (item) {
        lines.push(
          <div>
            <ContactLineComponent
              parentCallback={context_there.callbackFunction}
              contact={item}
            />
          </div>
        )
      })
      return lines
    }
    return (
      <div className="text-center">
        <Spin tip="Chargement..."></Spin>
      </div>
    )
  }

  renderUsers = (data) => {
    const context_there = this
    if (data) {
      const lines_dataset = data
      var lines = []
      lines_dataset.slice(-250).forEach(function (item) {
        lines.push(
          <div className="lines-inline">
            <ContactLineComponent
              parentCallback={context_there.callbackFunction}
              contact={item}
            />
          </div>
        )
      })
      return lines
    }
    return (
      <div className="text-center">
        <Spin tip="Chargement..."></Spin>
      </div>
    )
  }

  componentWillUnmount() {
    clearTimeout(this.intervalID)
  }

  render() {
    return (
      <div id="homepage">
        <Row>
          <Col sm={5} className="col-5-homepage">
            <div id="frequent_callers">
              <h3 style={{ textTransform: 'uppercase' }}>Contacts récurrents </h3>
              <Tooltip
                placement="right"
                arrowPointAtCenter
                title="Fonctionnalité bêta susceptible d'évoluer rapidement."
              >
                <div
                  style={{
                    display: 'inline-block',
                    color: '#7f8083',
                    float: 'right',
                    marginTop: '-35px',
                  }}
                >
                  {' '}
                  <FontAwesomeIcon icon={faAsterisk} />
                </div>{' '}
              </Tooltip>
              <Divider />
              <div id="content_frequent_callers">
                {this.renderContactLines(this.global.data_lines_status)}
              </div>
            </div>
          </Col>
          <Col sm={6} className="col-6-homepage">
            <div id="user_profile">
              <h3 style={{ textTransform: 'uppercase' }}>Votre profil</h3>
              <Divider />
              <div style={{ width: '50%', display: 'inline-block' }}>
                <div className="label-themed">Nom</div>
                <div className="input-showonly-themed">
                  {this.state.data_actual_user.last_name}
                </div>
              </div>
              <div style={{ width: '50%', display: 'inline-block' }}>
                <div className="label-themed">Prénom</div>
                <div className="input-showonly-themed">
                  {this.state.data_actual_user.first_name}
                </div>
              </div>
              <div style={{ width: '100%', display: 'inline-block' }}>
                <div className="label-themed">Email</div>
                <div className="input-showonly-themed">
                  {this.state.data_actual_user.mail}
                </div>
              </div>
              <div style={{ width: '33.33%', display: 'inline-block' }}>
                <div className="label-themed">Nom d'utilisateur</div>
                <div className="input-showonly-themed">
                  {this.state.data_actual_user.username}
                </div>
              </div>
              <div style={{ width: '33.33%', display: 'inline-block' }}>
                <div className="label-themed">Numéro</div>
                <div className="input-showonly-themed">
                  {this.state.data_actual_user.namecallerid}
                </div>
              </div>
              <div style={{ width: '33.33%', display: 'inline-block' }}>
                <div className="label-themed">Extension</div>
                <div className="input-showonly-themed">
                  {this.state.data_actual_user.extension}
                </div>
              </div>
            </div>
          </Col>
          <div className="users-col-homepage">
            <div id="users">
              <h3 style={{ textTransform: 'uppercase' }}>Utilisateurs</h3>
              <Divider />
              <div id="content_users">
                {this.renderUsers(this.global.data_lines_status_unsorted)}
              </div>
            </div>
          </div>
        </Row>
      </div>
    )
  }
}

export default HomePage
