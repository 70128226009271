import {
  faBackspace,
  faPhoneAlt,
  faTimes,
  faTimesCircle,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { rem } from 'polished'
import ClickNHold from 'react-click-n-hold'
import { Else, If, Then } from 'react-if'
import { connect } from 'react-redux'
import ScaleText from 'react-scale-text'
import React, { Component } from 'reactn'
import styled from 'styled-components'
import UIfx from 'uifx'
import { history } from '../../../action/history'
import { AddAccountUa } from '../../../action/uaConnection'
import DTMF3File from '../../../assets/164393_ialexs_dtmf-3.mp3'
import DTMF2File from '../../../assets/164394_ialexs_dtmf-2.mp3'
import DTMF1File from '../../../assets/164395_ialexs_dtmf-1.mp3'
import DTMF0File from '../../../assets/164396_ialexs_dtmf-0.mp3'
import DTMF7File from '../../../assets/164398_ialexs_dtmf-7.mp3'
import DTMF6File from '../../../assets/164399_ialexs_dtmf-6.mp3'
import DTMF5File from '../../../assets/164400_ialexs_dtmf-5.mp3'
import DTMF4File from '../../../assets/164401_ialexs_dtmf-4.mp3'
import DTMF9File from '../../../assets/164402_ialexs_dtmf-9.mp3'
import DTMF8File from '../../../assets/164403_ialexs_dtmf-8.mp3'
import DTMFstarFile from '../../../assets/164408_ialexs_dtmf-star.mp3'
import DTMFpoundFile from '../../../assets/164409_ialexs_dtmf-pound.mp3'
import { makeCall } from '../BodyPage/MakeCall'
import PopUpCall from '../PopUpCall/PopUpCall'
import TouchKeyBoard from './TouchKeyBoard'

// Icone

const DTMF_pound = new UIfx(DTMFpoundFile, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_star = new UIfx(DTMFstarFile, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_0 = new UIfx(DTMF0File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_1 = new UIfx(DTMF1File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_2 = new UIfx(DTMF2File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_3 = new UIfx(DTMF3File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_4 = new UIfx(DTMF4File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_5 = new UIfx(DTMF5File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_6 = new UIfx(DTMF6File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_7 = new UIfx(DTMF7File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_8 = new UIfx(DTMF8File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const DTMF_9 = new UIfx(DTMF9File, {
  volume: 0.5, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const ContainerKeyShow = styled.div`
  min-width: 420px;
  max-width: 420px;
  position: absolute; /* postulat de départ */
  top: 50%;
  left: 50%; /* à 50%/50% du parent référent */
  transform: translate(-50%, -50%); /* décalage de 50% de sa propre taille */
  background-color: #292929;
  z-index: 9999999;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;

  @media only screen and (max-width: 800px) {
    bottom: ${rem(113)};
    right: ${rem(55)};
  }

  @media only screen and (max-width: 600px) {
    right: ${rem(0)};
  }

  @media only screen and (max-width: 350px) {
    min-width: inherit;
    width: 100%;
  }
`

const ContainerInput = styled.div`
  display: block;
  height: 196px;
  margin: ${rem(1)};
  background-color: #292929;
  justify-content: space-between;
`

const InputNumber = styled.input`
  background-color: #292929;
  position: absolute;
  top: 15%;
  width: 90%;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: none;
  caret-color: white;
  color: #cc1915;

  :focus {
    outline: none;
    outline-color: none;
    outline-style: none;
    outline-width: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 30px;
    text-align: center;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 30px;
    text-align: center;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 30px;
    text-align: center;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flexdirection: row @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const ContainerTouchKey = styled.div`
  display: flex;
  flex: 2;
  flex-wrap: wrap;
  margin: 0;
`

const ContainerOption = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: ${rem(100)};
  max-width: ${rem(150)};
  background-color: #616161;
  border: ${rem(1)} solid #292929;

  @media only screen and (max-width: 600px) {
    flex-direction: row-reverse;
    max-width: 100%;
    height: ${rem(60)};
  }
`

const TouchDeleteNumber = styled.button`
  color: #a6aaae;
  background-color: #61616105;
  border: none;
  position: absolute;
  top: 2px;
  left: 0px;
  font-size: 1.2em;
`

const ContainerIconDeleteNumber = styled.div`
  color: #fafafa;
  background-color: #1e1e1e;
  height: 90px;
  z-index: 4;
  min-width: 140px;
  width: 33.33%;
  font-size: 36px;
  max-width: 140px;
  line-height: 75px;
  text-align: center;
  border: 1px solid #292929;
  cursor: pointer;
  img {
    margin: auto;
  }
`

const ContainerIconMakeCall = styled.div`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 90px;
  min-width: 140px;
  width: 33.33%;
  font-size: 36px;
  max-width: 140px;
  line-height: 75px;
  text-align: center;
  border: ${rem(1)} solid #292929;
  cursor: pointer;
  img {
    margin: auto;
  }
`

const ContainerIconAddContact = styled.div`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 90px;
  min-width: 140px;
  width: 33.33%;
  font-size: 36px;
  max-width: 140px;
  line-height: 75px;
  text-align: center;
  border: ${rem(1)} solid #292929;
  cursor: pointer;
  img {
    margin: auto;
  }

  a {
    margin: auto;
  }

  img {
    margin: auto;
  }
`

const ContenairPopUpCall = styled.div`
    margin-top: ${rem(0)}
    position: block;
    background-color: transparent;
    z-index: 3;
    border-radius: 12px

    h2 {
		padding-top: ${rem(5)};
        margin-left: ${rem(10)};
        margin-right: ${rem(10)};
        color: white;
        font-size: 1em;
    }

    p {
        color: white;
    }
    `

const EndCall = styled.button`
  background-color: transparent;
  color: #fafafa;
  height: 90px;
  min-width: 140px;
  width: 33.33%;
  font-size: 36px;
  max-width: 140px;
  line-height: 75px;
  text-align: center;
  margin-top: 93.705%;
  border: 0.0625rem solid transparent;
  z-index: 999999999;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const ButtonMute = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
`

const ButtonHold = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const Transfert = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const TransfertSup = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const InputNumberTransfert = styled.input`
  background-color: #292929;
  font-size: 30px;
  font-weight: bold;
  color: #fafafa;
  width: 420px;
  max-width: 420px !important;
  text-align: center;
  border: none;
  caret-color: white;

  :focus {
    outline: none;
    outline-color: none;
    outline-style: none;
    outline-width: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    margin-top: -5px;
    font-size: 16px;
    text-align: center;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    top: -5px;
    font-size: 16px;
    text-align: center;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    top: -5px;
    font-size: 16px;
    text-align: center;
  }
`

const DtmfButton = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const TransfertBlock = styled.div`
  position: absolute;
  margin-top: 490px;
  width: 420px;
  max-width: 420px !important;
  background-color: #1e1e1e;
  border: 0.0625rem solid #292929;
  margin-left: 0px;
  left: 0px;
`

const DisplayCall = styled.ul`
  p {
    display: inline-block;
    border-left: 1px solid #292929;
    margin-top: ${rem(5)};
    cursor: pointer;
  }
`

class KeyBoard extends Component {
  constructor(props) {
    super(props)

    this.touchInfo = [
      {
        number: 1,
        infoTouch: '',
      },
      {
        number: 2,
        infoTouch: 'ABC',
      },
      {
        number: 3,
        infoTouch: 'DEF',
      },
      {
        number: 4,
        infoTouch: 'GHI',
      },
      {
        number: 5,
        infoTouch: 'JKL',
      },
      {
        number: 6,
        infoTouch: 'MNO',
      },
      {
        number: 7,
        infoTouch: 'PQRS',
      },
      {
        number: 8,
        infoTouch: 'TUV',
      },
      {
        number: 9,
        infoTouch: 'WXYZ',
      },
      {
        number: '*',
        infoTouch: '',
      },
      {
        number: 0,
        infoTouch: '+',
      },
      {
        number: '#',
        infoTouch: '',
      },
    ]
    this.state = {
      number: '',
      fontSize: 30,
      isConnected: null,
      popUpCall: false,
      key: null,
    }
  }

  componentDidMount = async () => {}

  componentWillReceiveProps(nextProps) {
    this.setState({
      isConnected: nextProps.ua,
    })
  }

  onFormSubmit = async (e) => {
    console.log('onFormSubmit')
    e.preventDefault()
    this.setGlobal({
      callingFromWeb: true,
    })
    this.makeCall(this.global.number)
    // makeCall.bind(this.props.ua, this.global.number);
    // send to server with e.g. `window.fetch`
  }

  makeCall = async (element) => {
    if (this.global.makeCall_clicked) {
      console.log('Already clicked MakeCall')
    } else {
      await this.setGlobal({
        opened: true,
        makeCall_clicked: true,
      })
      await makeCall(this.props.ua, this.global.number, 'Call to')
      await this.setGlobal({
        makeCall_clicked: false,
      })
    }
  }

  start = async (e) => {
    console.log('START')
    console.log(e)
    await this.setState({ key: e })
  }

  end = (e, enough) => {
    console.log(this.state.key)
    console.log(enough)

    if (!enough) {
      console.log('Not long press')
      this.eventClickChild(this.state.key)

      //this.sendTouchParent(this.props.info.number)
    } else {
      console.log('Long press')
      let long = true
      this.eventClickChild(this.state.key, long)
      //let key = this.props.info.number;
      /* if (key === 0) {
          console.log('in the IF')
          this.sendTouchParent("+")
        } */
    }

    console.log('END')
    console.log(
      enough ? 'Click released after enough time' : 'Click released too soon'
    )
  }

  clickNHold(e) {
    console.log('CLICK AND HOLD')
  }

  eventClickChild = async (data, long) => {
    console.log(data)
    console.log('long:')
    console.log(long)
    if (this.global.in_communication) {
      console.log('In communication so DTMF')
      console.log(data.number.toString())

      if (data.number.toString() === '#') {
        DTMF_pound.play()
      }

      if (data.number.toString() === '*') {
        DTMF_star.play()
      }

      if (data.number.toString() === '0') {
        DTMF_0.play()
      }

      if (data.number.toString() === '1') {
        DTMF_1.play()
      }

      if (data.number.toString() === '2') {
        DTMF_2.play()
      }

      if (data.number.toString() === '3') {
        DTMF_3.play()
      }

      if (data.number.toString() === '4') {
        DTMF_4.play()
      }

      if (data.number.toString() === '5') {
        DTMF_5.play()
      }

      if (data.number.toString() === '6') {
        DTMF_6.play()
      }

      if (data.number.toString() === '7') {
        DTMF_7.play()
      }

      if (data.number.toString() === '8') {
        DTMF_8.play()
      }

      if (data.number.toString() === '9') {
        DTMF_9.play()
      }
      this.dtmf(data.number.toString())
    } else {
      console.log('Not in communication so normal number typing')
      console.log(data)
      console.log(data.number.toString())
      let t = ''
      if (this.global.number) {
        t = this.global.number.toString()
      }

      let t2 = data.number.toString()

      if (t2 === '0' && long) {
        t2 = '+'
      }

      if (t2 === '2' && long) {
        t2 = 'A'
      }

      if (t2 === '3' && long) {
        t2 = 'D'
      }

      if (t2 === '4' && long) {
        t2 = 'G'
      }

      if (t2 === '5' && long) {
        t2 = 'J'
      }

      if (t2 === '6' && long) {
        t2 = 'M'
      }

      if (t2 === '7' && long) {
        t2 = 'P'
      }

      if (t2 === '8' && long) {
        t2 = 'T'
      }

      if (t2 === '9' && long) {
        t2 = 'W'
      }

      const r = t + t2
      await this.setGlobal({
        number: r,
      })
    }
  }

  getValueInput = async (e) => {
    console.log(e.target.value)
    await this.setGlobal({
      number: e.target.value.toString(),
    })
  }

  dtmf = (e) => {
    this.global.infoCall.sendDTMF(e.toString())
    if (e >= 0 && e <= 9) {
      //  this.props.infoCall.sendDTMF(e)
      this.global.infoCall.sendDTMF(e.toString())
      this.setState({
        dtmf: this.state.dtmf + e,
      })
    }
  }

  closeModal = async (e) => {
    if (this.global.in_communication) {
      console.log('Need terminate call first')
    } else {
      this.setGlobal({
        opened: false,
      })
    }
  }

  deleteTheNumber = async (e) => {
    console.log('deleteTheNumber')
    if (this.global.number) {
      this.setState({
        number: this.global.number.substring(0, this.global.number.length - 1),
      })
      this.setGlobal({
        number: this.global.number.substring(0, this.global.number.length - 1),
      })
    }
  }

  checkNumberEnter = async () => {
    this.setState({
      popUpCall: <PopUpCall infoCall="outgoing" infoNumber={this.global.number} />,
    })
  }

  async go_to_add_contact() {
    history.push('/contacts/add')
  }

  render() {
    return (
      <ContainerKeyShow>
        <ContainerInput>
          <If condition={this.global.in_communication}>
            <Then>
              <div />
            </Then>
            <Else>
              <form onSubmit={this.onFormSubmit}>
                <ScaleText>
                  <InputNumber
                    autoFocus
                    pattern="^(\+|[*]{0,1}[#]{0,1})[0-9.\(\)\s-]{0,}#{0,1}$"
                    placeholder="Composer un numéro"
                    value={this.global.number ? this.global.number : ''}
                    style={{ fontSize: `${this.state.fontSize}` }}
                    onChange={this.getValueInput.bind(this)}
                  />
                </ScaleText>
              </form>
            </Else>
          </If>
          <TouchDeleteNumber onClick={this.closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </TouchDeleteNumber>
        </ContainerInput>
        <Container>
          <ContainerTouchKey className="KeyBoard">
            {this.touchInfo.map((item, i) => (
              <ClickNHold
                time={1} // Time to keep pressing. Default is 2
                onStart={() => this.start(item)}
                //onStart={this.start(item)} // Start callback
                onClickNHold={this.clickNHold} //Timeout callback
                onEnd={this.end}
              >
                <TouchKeyBoard
                  //  onClick={() => this.eventClickChild(item)}
                  key={i}
                  info={item}
                />
              </ClickNHold>
            ))}

            <ContainerIconAddContact
            // onClick={() => this.go_to_add_contact("dashboard")}
            >
              <FontAwesomeIcon icon={faUserPlus} className="" />
              <div style={{ fontSize: '10px', lineHeight: '0px' }}>
                Ajouter un contact
              </div>
            </ContainerIconAddContact>
            <If condition={this.global.in_communication}>
              <Then>
                <ContainerIconMakeCall
                  onClick={() => this.setGlobal({ want_endcall: true })}
                >
                  {' '}
                  <FontAwesomeIcon icon={faTimesCircle} color="#FF0000" />
                </ContainerIconMakeCall>
              </Then>
              <Else>
                <ContainerIconMakeCall
                  onClick={() => this.makeCall(this.global.number)}
                >
                  {' '}
                  <FontAwesomeIcon icon={faPhoneAlt} style={{ color: '#49aa19' }} />
                </ContainerIconMakeCall>
              </Else>
            </If>

            <ContainerIconDeleteNumber onClick={this.deleteTheNumber}>
              <FontAwesomeIcon icon={faBackspace} className="" />
            </ContainerIconDeleteNumber>
          </ContainerTouchKey>

          {/* <ContainerOption>
            <ContainerIconDeleteNumber onClick={this.deleteTheNumber}>
              <img
                src={iconDelete}
                alt="Icone pour supprimer des chiffres dans la numérotation"
              />
            </ContainerIconDeleteNumber>
            <ContainerIconMakeCall
              onClick={makeCall.bind(this, this.props.ua, this.state.number)}
            >
              <img src={iconMakeCall} alt="Icone pour valider" />
            </ContainerIconMakeCall>
            <ContainerIconAddContact>
              <Link to={"/contacts/add"}>
                <img
                  src={iconAddContact}
                  alt="Icone pour ajouter un nouveau contact"
                />
              </Link>
            </ContainerIconAddContact>
          </ContainerOption> */}
        </Container>
      </ContainerKeyShow>
    )
  }
}

const mapStateToProps = (state) => ({
  ua: state.ua.ua,
})

export default connect(mapStateToProps, { AddAccountUa })(KeyBoard)
