import 'bootstrap/dist/css/bootstrap.min.css'

import { Button, Checkbox, Form, Input, notification, Modal, Tooltip } from 'antd'
import { Col, Container, Row } from 'react-bootstrap'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import React, { Component, setGlobal } from 'reactn'
import { getDataUser, getTokenAutho } from '../Js/dataUserGeneral'
import { getValueDnd, setDndOff, setDndOn } from '../Js/dnd'

import { AddAccountUa } from '../action/uaConnection'
import { AddInfoServe } from '../action/infoServe'
import { Helmet } from 'react-helmet'
import JsSIP from 'jssip'
import LoginBackground_1 from '../assets/Background_Login_1.jpg'
import LoginBackground_2 from '../assets/Background_Login_2.jpg'
import LoginBackground_3 from '../assets/Background_Login_3.jpg'
import LoginBackground_4 from '../assets/Background_Login_4.jpg'
import { ReactComponent as Logo } from '../assets/LogoNextcall.svg'
import { connect } from 'react-redux'
import { history } from '../action/history.js'
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons'
import axios from 'axios'

const arraySort = require('array-sort')
// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT
const HEAD_TITLE = 'NextCall Softphone'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      typeInput: false,
      userName: '',
      password: '',
      loggin: false,
      checkRecaptcha: false,
      errorConnection: false,
      visible: false,
      resetEmail: null,
      resetUser: null,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentWillMount = () => {
    const random = Math.floor(Math.random() * 4) + 1
    var background = null
    switch (random) {
      case 1:
        background = LoginBackground_1
        this.setState({ background: background })
        break
      case 2:
        background = LoginBackground_2
        this.setState({ background: background })
        break
      case 3:
        background = LoginBackground_3
        this.setState({ background: background })
        break
      case 4:
        background = LoginBackground_4
        this.setState({ background: background })
        break
      default:
        break
    }
  }

  componentDidMount = () => {
    // loadReCaptcha("6LeNvcQUAAAAAM2AFJO61nqxl_2vNvWTe7P6avt0")
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    this.setState({
      checkRecaptcha: true,
    })
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.type === 'password' ? target.value : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  submitConnection = async (values) => {
    const context_there = this
    const username = values.username
    const password = values.password
    let userInfo = {
      userName: username,
      password: password,
    }
    let dataServer = await getTokenAutho(userInfo)
    if (dataServer.status === 200) {
      await this.props.AddInfoServe(dataServer.data)
      let uri =
        'sip:' +
        dataServer.data.userInfo.parentName +
        '%23' +
        dataServer.data.userInfo.extension +
        '-web@' +
        dataServer.data.infoServer.domain +
        ':' +
        dataServer.data.infoServer.portpjsip
      var socket = new JsSIP.WebSocketInterface(
        'wss://' + dataServer.data.infoServer.domain + ':8089/ws'
      )
      socket.via_transport = 'udp'
      var configuration = {
        sockets: [socket],
        uri: uri,
        password: dataServer.data.userInfo.asteriskSecret,
        iceServers: ['stun:stun.l.google.com:19302'],
      }
      var ua = new JsSIP.UA(configuration)

      ua.start()
      await this.props.AddAccountUa(ua)
      await localStorage.setItem(
        'user',
        JSON.stringify(this.props.dataConnectionServer)
      )
      await this.setState({
        loggin: true,
      })
      //const nodeBadge = document.querySelector(".grecaptcha-badge");

      try {
        const actual_user = await getDataUser()
        if (actual_user !== null) {
          await this.setGlobal({
            data_actual_user: actual_user,
          })
          localStorage.setItem('data_actual_user', JSON.stringify(actual_user))
        }
      } catch (error) {
        console.log(error)
      }

      history.push('/')
      window.location.reload()
    } else {
      console.log('errorOnConnection')
      notification.open({
        type: 'error',
        message: "Une erreur s'est produite",
        description: 'Veuillez vérifier votre mot de passe et login',
      })
      this.setState({
        errorConnection: true,
      })
    }
  }

  showPassword = (e) => {
    e.preventDefault()
    const { typeInput } = this.state
    this.setState({
      typeInput: !typeInput,
    })
  }

  onChange = async (e) => {
    console.log(e.target.value)
    await this.setState({ resetEmail: e.target.value.toString() })
  }

  onClickEmailReset = async () => {
    console.log('Asking e-mail reset with email : ' + this.state.resetEmail)
    await axios
      .get('https://' + SRV_ENDPOINT + ':3003/api/reset_pw/' + this.state.resetEmail)
      .then(async (res) => {
        console.log(res.data)
        await this.setState({ resetUser: res.data, visible: false })
        await this.sendResetEmail()
      })
  }

  sendResetEmail = async () => {
    await axios
      .get(
        'https://' + SRV_ENDPOINT + ':8080/sendMailApi/' + this.state.resetUser.id
      )
      .then((res) => {
        console.log(res)
        if (res.data.result === 'Mail envoyé avec succès!') {
          notification.open({
            type: 'success',
            message: 'E-mail envoyé',
            description:
              'Vous allez recevoir vos identifiants par e-mail rapidement',
          })
        } else {
          notification.open({
            type: 'error',
            message: "Une erreur s'est produite",
            description: 'Merci de contacter votre représentant ASII',
          })
        }
      })
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{HEAD_TITLE}</title>
        </Helmet>
        <Container
          fluid
          style={{
            backgroundImage: `url(${this.state.background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <Row>
            <Col
              style={{
                backgroundColor: '#121212',
                padding: '25px',
                height: '100vh',
              }}
              sm={4}
            >
              <div
                style={{
                  marginTop: '30%',
                  width: '75%',
                  textAlign: 'left',
                  marginLeft: '15%',
                }}
              >
                <Logo style={{ width: '85%' }} />
                <div
                  style={{
                    fontFamily: 'open sans',
                    fontSize: '18px',
                    color: '#6F6E6E',
                    marginTop: '55px',
                    marginBottom: '15px',
                  }}
                >
                  <b>Bienvenue sur la version web de Nextcall</b>
                </div>
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={this.submitConnection}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Merci d'entrer vos identifiants !",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Utilisateur"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Merci d'entrer votre mot de passe !",
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Mot de passe"
                    />
                  </Form.Item>
                  {/*                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </Form.Item>*/}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Se connecter
                    </Button>
                    <br />
                  </Form.Item>
                </Form>
                <p>
                  <a
                    onClick={this.showModal}
                    style={{ color: '#189995', fontSize: '12px' }}
                  >
                    Mot de passe / Identifiants oubliés
                  </a>
                </p>
              </div>
            </Col>
            <Col></Col>
            <Modal
              title="Récupération de vos identifiants"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={false}
            >
              <p>
                Si vous souhaitez <b>re-initialiser vos identifiants</b> Nextcall
                (Web et Mobile)
              </p>
              <p>Merci d'indiquer votre e-mail ci-dessous</p>
              <p>
                Vous recevrez vos identifiants et le QRCode de connexion par e-mail.
              </p>
              <p>
                <Input
                  style={{ width: '75%', marginRight: '10px' }}
                  placeholder="Saisir votre e-mail"
                  onChange={this.onChange}
                  suffix={
                    <Tooltip title="Votre e-mail professionnel lié à votre compte Nextcall / ASII">
                      <InfoCircleOutlined style={{ color: 'white' }} />
                    </Tooltip>
                  }
                />
                <Button onClick={this.onClickEmailReset} type="primary">
                  Envoyer
                </Button>
              </p>
            </Modal>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ua: state.ua.ua,
    dataConnectionServer: state.dataConnectionServer.dataConnectionServer,
  }
}

export default connect(mapStateToProps, { AddAccountUa, AddInfoServe })(Login)
