import {
  Badge,
  Button,
  Divider,
  Input,
  Layout,
  Menu,
  Modal,
  Select,
  Tag,
  Tooltip,
  Typography,
  notification,
} from 'antd'
import React, { Component } from 'reactn'

import AcceptOrDecline from './AcceptOrDecline'
import { AddAccountUa } from '../../../action/uaConnection'
import JsSIP from 'jssip'
import ManagementCall from './ManagementCall'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { optionCalls } from '../../../Js/pjsipLib'
import styled from 'styled-components'

const ContenairPopUpCall = styled.div`
    min-width: 420px;
    max-width: 420px;
    position: absolute;
    top: calc(50% - 219px);
    text-align: center;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background-color: transparent;
    z-index: 9999999;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
`

class PopUpCall extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popUpCall: true,
      managementCall: false,
      sessionCall: false,
      checkLocalStorage: false,
      isConnected: null,
      essai: null,
    }
  }

  componentWillMount = async () => {}

  componentDidMount = async () => {
    if (window.location.href.split('/')[3] !== 'login') {
      if (this.props.ua === null) {
        if (JSON.parse(localStorage.getItem('user') !== null)) {
          if (
            typeof JSON.parse(localStorage.getItem('user')).userInfo !== 'undefined'
          ) {
            const dataConnection = JSON.parse(localStorage.getItem('user'))
            const uri = `sip:${dataConnection.userInfo.parentName}%23${dataConnection.userInfo.extension}-web@${dataConnection.infoServer.domain}${dataConnection.infoServer.portpjsip}`

            let socket
            if (
              dataConnection.infoServer.domain === 'srv.petitefriture.nextcall.io'
            ) {
              socket = new JsSIP.WebSocketInterface(
                'wss://' + dataConnection.infoServer.domain + ':8089/ws'
              )
            } else {
              socket = new JsSIP.WebSocketInterface(
                'wss://' + dataConnection.infoServer.domain + ':8089/ws'
              )
            }

            socket.via_transport = 'udp'
            const configuration = {
              sockets: [socket],
              uri,
              password: dataConnection.userInfo.asteriskSecret,
              iceServers: ['stun:stun.l.google.com:19302'],
            }
            const uaLocal = await new JsSIP.UA(configuration)
            uaLocal.start()
            await this.props.dispatch(AddAccountUa(uaLocal))
          } else {
            localStorage.clear()
            this.setState({
              checkLocalStorage: true,
            })
          }
        } else {
          localStorage.clear()
          this.setState({
            checkLocalStorage: true,
          })
        }
      }
    }
  }

  componentDidUpdate = () => {}

  componentWillReceiveProps = async (nextProps, prevState) => {
    await this.setState({
      isConnected: nextProps.ua,
    })
    if (this.state.essai === null) await this.gestionCallEvent(nextProps.ua)
    await this.setState({
      essai: 'lol',
    })
  }

  eventHoldInMultiCall = (eSession) => {
    const size = Object.keys(this.props.session).length
    let holdForMultiCall = false
    this.setState({
      managementCall: this.props.session.map((item, i) => {
        if (item.id !== eSession.id) {
          if (size > 1) {
            holdForMultiCall = true
          }
        } else {
          holdForMultiCall = false
        }
        if (item._is_confirmed) {
          return (
            <ManagementCall
              onClick={this.eventHoldInMultiCall}
              statusCall="En Communication"
              infoHoldInCall={holdForMultiCall}
              key={i}
              infoCall={item}
            />
          )
        }
      }),
    })
  }

  gestionCallEvent = async (connectToAsterisk) => {
    const context_there = this
    const ua = await this.state.isConnected
    if (ua !== null && typeof ua !== 'undefined') {
      const that = await this
      await ua.on('newRTCSession', async function (data) {
        const { session } = data
        const sessionCall = {
          type: 'ADD_CALL_SESSION',
          value: {
            session,
          },
        }
        if (session._direction === 'incoming') {
          that.props.dispatch(sessionCall)
        } else {
          console.log('in the else line 138')
          await that.props.dispatch(sessionCall)
          await that.setState({
            managementCall: that.props.session.map((item, i) => (
              <ManagementCall key={i} infoCall={item} errorCall="null" />
            )),
          })
        }
        session.on('progress', async (e) => {
          if (
            session._direction === 'incoming' &&
            session._is_confirmed === false &&
            context_there.global.incoming_call_active === false
          ) {
            const { opened, activeButton } = context_there.global
            console.log(context_there.global)
            context_there.setGlobal({
              opened: !opened,
              activeButton: !activeButton,
            })
            console.log('popupcall')
            context_there.setGlobal({
              incoming_call_active: true,
              call_active_uuid: null,
              call_in_pause_uuid: null,
            })
            that.setState({
              popUpCall: (
                <AcceptOrDecline
                  onClick={that.acceptOrDecline}
                  infoCall="Appel entrant"
                  infoSession={session}
                  infoOption={optionCalls}
                />
              ),
              sessionCall: session,
            })
          } else if (
            session._direction === 'incoming' &&
            session._is_confirmed === false &&
            context_there.global.incoming_call_active === true
          ) {
            console.log('Incoming call meanwhile already on a call')
            notification.open({
              type: 'info',
              message: 'Appel entrant',
              description:
                'Vous venez de recevoir un appel en absence de ' +
                  that.props.session[1]._request.from._display_name || 'Inconnu',
            })
            await context_there.setGlobal({
              alr_in_call: true,
            })
            that.props.session.map((item, i) => {
              console.log(item)
              if (item._is_confirmed === false) {
                item.terminate()
              }
            })
            // await this.setGlobal({ number: null, in_communication: null });
          } else {
            console.log('in the else line 199')
            that.setState({
              managementCall: that.props.session.map((item, i) => (
                <ManagementCall
                  onClick={that.eventHoldInMultiCall}
                  errorCall={e.cause}
                  key={i}
                  infoCall={item}
                />
              )),
            })
          }
        })
        session.on('ended', that.endedEvent.bind(this, that, session))
        session.on('failed', that.failedEvent.bind(this, that, session))
        session.on('accepted', async (e) => {
          const size = Object.keys(that.props.session).length
          let holdForMultiCall = false
          console.log('line 218')
          that.setState({
            managementCall: that.props.session.map((item, i) => {
              if (item.id !== session.id) {
                if (size > 1) {
                  holdForMultiCall = true
                }
              } else {
                holdForMultiCall = false
              }
              console.log('in the else line 228')
              return (
                <ManagementCall
                  onClick={that.eventHoldInMultiCall}
                  errorCall={e.cause}
                  infoHoldInCall={holdForMultiCall}
                  key={i}
                  infoCall={item}
                />
              )
            }),
          })
        })

        session.on('confirmed', (e) => {})
        if (session.direction === 'incoming') {
          session.on('peerconnection', (data) => {
            session.connection.addEventListener('addstream', (e) => {
              const audio = document.createElement('audio')
              audio.srcObject = e.stream
              audio.play()
            })
          })
        }
      })
    }
  }

  deleteSessionCall = async (that, session) => {
    console.log('deleteSessionCall function')
    //        this.props.infoCall.terminate();
    // session.terminate();
    const sessionCallDelete = {
      type: 'DELETE_CALL_SESSION',
      value: {
        session,
      },
    }
    await that.setGlobal({ incoming_call_active: false })
    await that.props.dispatch(sessionCallDelete)
    await that.setGlobal({
      want_endcall: false,
    })
    setTimeout(() => {
      that.setState({
        popUpCall: that.props.session.map((item, i) => {
          console.log(item)
          if (item._is_confirmed === false) {
            return (
              <AcceptOrDecline
                onClick={that.acceptOrDecline}
                key={i}
                infoCall="incoming"
                infoSession={item}
                infoOption={optionCalls}
              />
            )
          }
        }),

        managementCall: that.props.session.map((item, i) => {
          console.log('in managementCall under setTimeout')
          console.log('in the else line 284')
          console.log(item)
          if (
            item._is_confirmed &&
            item._localMediaStreamLocallyGenerated_localMediaStreamLocallyGenerated !==
              false
          ) {
            console.log('in the else line 293')
            return (
              <ManagementCall
                onClick={that.eventHoldInMultiCall}
                key={i}
                infoCall={item}
              />
            )
          }
          if (item._id === session._id && that.global.alr_in_call === false) {
            console.log('in the else line 303')
            return (
              <ManagementCall
                onClick={that.eventHoldInMultiCall}
                key={i}
                infoCall={item}
              />
            )
          }
        }),
      })
    }, 500)
  }

  failedEvent = async (that, session, e) => {
    const context_there = this
    await context_there.setGlobal({
      incoming_call_active: false,
    })
    await context_there.setGlobal({
      want_endcall: false,
    })
    console.log('failedEvent function')
    if (that.props.session[0] !== null) {
      that.setState({
        managementCall: that.props.session.map((item, i) => {
          console.log(item)
          if (
            item._is_confirmed &&
            item._localMediaStreamLocallyGenerated_localMediaStreamLocallyGenerated !==
              false &&
            that.global.alr_in_call === false
          ) {
            console.log('in the else line 332')
            return (
              <ManagementCall
                errorCall={e.cause}
                onClick={that.eventHoldInMultiCall}
                key={i}
                infoCall={item}
              />
            )
          }
          if (item._id === session._id && that.global.alr_in_call === false) {
            console.log('in the else line 343')
            return (
              <ManagementCall
                errorCall={e.cause}
                onClick={that.eventHoldInMultiCall}
                key={i}
                infoCall={item}
              />
            )
          }
        }),
        popUpCall: that.props.session.map((item, i) => {
          if (item._is_confirmed === false && item._id !== session._id) {
            return (
              <AcceptOrDecline
                onClick={that.acceptOrDecline}
                key={i}
                infoCall="incoming"
                infoSession={item}
                infoOption={optionCalls}
              />
            )
          }
        }),
      })
      context_there.setGlobal({
        incoming_call_active: false,
      })
      that.deleteSessionCall(that, session)
    } else {
      that.setState({
        managementCall: null,
      })
    }
    that.closePopUp(that)
  }

  endedEvent = async (that, session, e) => {
    const context_there = this
    context_there.setGlobal({
      incoming_call_active: false,
    })
    if (that.props.session[0] !== null) {
      console.log('endedEvent function')
      that.setState({
        managementCall: that.props.session.map((item, i) => {
          console.log(item)
          if (
            item._is_confirmed &&
            item._localMediaStreamLocallyGenerated_localMediaStreamLocallyGenerated !==
              false
          ) {
            return (
              <ManagementCall
                errorCall={e.cause}
                onClick={that.eventHoldInMultiCall}
                key={i}
                infoCall={item}
              />
            )
          }
        }),
        popUpCall: that.props.session.map((item, i) => {
          if (item._is_confirmed === false) {
            return (
              <AcceptOrDecline
                onClick={that.acceptOrDecline}
                key={i}
                infoCall="incoming"
                infoSession={item}
                infoOption={optionCalls}
              />
            )
          }
        }),
      })
      context_there.setGlobal({
        incoming_call_active: false,
      })
      that.deleteSessionCall(that, session)
    } else {
      setTimeout(() => {
        that.setState({
          managementCall: null,
        })
      }, 500)
    }
    that.closePopUp(that)
  }

  progressEvent = () => {}

  acceptedEvent = () => {}

  confirmedEvent = () => {}

  closePopUp = (that) => {}

  acceptCall = async (e) => {
    this.props.onClick(e.target.value)
    if (this.props.infoSession)
      this.props.infoSession.answer(this.props.infoOption.optionCalls)
  }

  declineCall = async (e) => {
    this.props.onClick(e.target.value)
    console.log('in declineCall PopUpCall')
    console.log(this.props.infoSession)
    this.props.infoSession.terminate()
    await this.setGlobal({
      number: null,
      in_communication: null,
    })
  }

  acceptOrDecline = async (e) => {
    this.setState({
      popUpCall: true,
    })
    if (e === false) {
      this.setState({
        managementCall: false,
      })
    }
  }

  render() {
    return (
      <ContenairPopUpCall>
        {' '}
        {this.state.popUpCall} {this.state.managementCall}{' '}
        {this.state.checkLocalStorage ? <Redirect to="/login" /> : null}{' '}
      </ContenairPopUpCall>
    )
  }
}

const mapStateToProps = (state) => ({
  ua: state.ua.ua,
  session: state.session.session,
})

export default connect(mapStateToProps)(PopUpCall)
