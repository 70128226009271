// ACTION REDUX FOR CONNECTION TO ASTERISK
import { ACTUEL_UA, ADD_ACCOUNT_PJSIP } from '../components/utils/UAConstant'

export function AddAccountUa(ua) {
  return {
    type: ADD_ACCOUNT_PJSIP,
    ua: ua,
  }
}

export function getActuelConnectionUa(ua) {
  return {
    type: ACTUEL_UA,
    ua: ua,
  }
}
