import { createStore, combineReducers } from 'redux'

import changePageInfo from './Reducers/ActualPage'
import accountUserPjsip from './Reducers/AccountUserPjsip'
import saveSession from './Reducers/SaveSession'
import dataServer from './Reducers/SaveDataServer'
import changeEtatNavBar from './Reducers/EtatNavBar'

import throttle from 'lodash.throttle'
import { loadState, saveState } from './localStorage'

const persistedState = loadState()
const store = createStore(dataServer, persistedState)

store.subscribe(
  throttle(() => {
    saveState({
      dataServer: store.getState().dataServer,
    })
  }, 1000)
)

const reducers = combineReducers({
  page: changePageInfo,
  navBarEtat: changeEtatNavBar,
  dataConnectionServer: dataServer,
  ua: accountUserPjsip,
  session: saveSession,
})

export default createStore(reducers)
