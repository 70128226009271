import { ADD_ACCOUNT_PJSIP } from '../../components/utils/UAConstant'

const initialState = {
  ua: null,
}

function accountUserPjsip(state = initialState, action) {
  let nextState
  switch (action.type) {
    case ADD_ACCOUNT_PJSIP:
      nextState = {
        ...state,
        ua: action.ua,
      }
      return nextState || state
    default:
      return state
  }
}

export default accountUserPjsip
