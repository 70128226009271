import * as moment from 'moment'

import React, { Component, setGlobal } from 'reactn'
import {
  faExchangeAlt,
  faMicrophone,
  faMicrophoneSlash,
  faPause,
  faPlay,
  faRandom,
  faTh,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Button, Checkbox, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { rem } from 'polished'
import styled from 'styled-components'
import ReactCountryFlag from 'react-country-flag'
import axios from 'axios'

const arraySort = require('array-sort')
// Important values //
const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT
const SRV_ENDPOINT = process.env.REACT_APP_SRV_ENDPOINT
const WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT
const HEAD_TITLE = 'NextCall Softphone'
const PhoneNumber = require('awesome-phonenumber')
const lookup = require('country-code-lookup')
const ContenairPopUpCall = styled.div`
    margin-top: ${rem(0)}
    position: block;
    background-color: transparent;
    z-index: 3;
    border-radius: 12px

    h2 {
		padding-top: ${rem(5)};
        margin-left: ${rem(10)};
        margin-right: ${rem(10)};
        color: white;
        font-size: 1em;
    }

    p {
        color: white;
    }
    `

const EndCall = styled.button`
  background-color: transparent;
  color: #fafafa;
  height: 90px;
  min-width: 140px;
  width: 33.33%;
  font-size: 36px;
  max-width: 140px;
  line-height: 75px;
  text-align: center;
  margin-top: 93.705%;
  border: 0.0625rem solid transparent;
  z-index: 999999999;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const ButtonMute = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
`

const ButtonHold = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const Transfert = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const TransfertSup = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const InputNumberTransfert = styled.input`
  background-color: #292929;
  font-size: 30px;
  font-weight: bold;
  color: #fafafa;
  width: 354px;
  max-width: 354px !important;
  text-align: center;
  border: none;
  float: left;
  caret-color: white;

  :focus {
    outline: none;
    outline-color: none;
    outline-style: none;
    outline-width: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    margin-top: -5px;
    font-size: 14px;
    text-align: center;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    top: -5px;
    font-size: 14px;
    text-align: center;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    top: -5px;
    font-size: 14px;
    text-align: center;
  }
`

const DtmfButton = styled.button`
  color: #fafafa;
  z-index: 4;
  background-color: #1e1e1e;
  height: 50px;
  width: 25%;
  font-size: 15px;
  border: 0.0625rem solid #292929;
  cursor: pointer;
  cursor: pointer;
`

const TransfertBlock = styled.div`
  position: absolute;
  margin-top: calc(119.25%);
  width: 420px;
  max-width: 420px !important;
  background-color: #1e1e1e;
  border: 0.0625rem solid #292929;
  margin-left: 0px;
  left: 0px;
`

const DisplayCall = styled.ul`
  p {
    display: inline-block;
    border-left: 1px solid #292929;
    margin-top: ${rem(5)};
    cursor: pointer;
  }
`

var countryCode = 'FR'

class ManagementCall extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMute: 'Muet',
      isHold: null,
      isTransfert: false,
      isTransfertSup: false,
      isDtmf: false,
      number: '',
      dtmf: '',
      minutes: null,
      seconds: null,
      countryCode: 'FR',
    }
  }

  componentWillMount() {
    let phone_to_parse_original
    let phone_to_parse_asString
    let phone_to_parse_plus

    if (
      this.props.infoCall._remote_identity.uri._user.toString().startsWith('000')
    ) {
      console.log('Prefixed Call')
      phone_to_parse_original = parseInt(
        this.props.infoCall._remote_identity.uri._user
      )
      phone_to_parse_asString = phone_to_parse_original.toString()
      phone_to_parse_plus = '+' + phone_to_parse_asString
    } else {
      console.log('French call with no prefix, default')
      phone_to_parse_original = parseInt(
        this.props.infoCall._remote_identity.uri._user
      )
      phone_to_parse_asString = '33' + phone_to_parse_original.toString()
      phone_to_parse_plus = '+' + phone_to_parse_asString
    }

    console.log('Detected country :')
    const detectedCountryCode = PhoneNumber(phone_to_parse_plus).getRegionCode()
    countryCode = detectedCountryCode
  }

  componentDidMount = async () => {
    console.log('componentDidMount ManagementCall')
    await this.setGlobal({ in_communication: true })
    let phone_to_parse_original
    let phone_to_parse_asString
    let phone_to_parse_plus

    if (
      this.props.infoCall._remote_identity.uri._user.toString().startsWith('000')
    ) {
      console.log('Prefixed Call')
      phone_to_parse_original = parseInt(
        this.props.infoCall._remote_identity.uri._user
      )
      phone_to_parse_asString = phone_to_parse_original.toString()
      phone_to_parse_plus = '+' + phone_to_parse_asString
    } else {
      console.log('French call with no prefix, default')
      phone_to_parse_original = parseInt(
        this.props.infoCall._remote_identity.uri._user
      )
      phone_to_parse_asString = '33' + phone_to_parse_original.toString()
      phone_to_parse_plus = '+' + phone_to_parse_asString
    }

    console.log('Detected country :')
    const detectedCountryCode = PhoneNumber(phone_to_parse_plus).getRegionCode()
    countryCode = detectedCountryCode
  }

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    console.log('prop number')
    console.log(this.props.infoCall._remote_identity.uri._user)

    let phone_to_parse_original
    let phone_to_parse_asString
    let phone_to_parse_plus

    if (
      this.props.infoCall._remote_identity.uri._user.toString().startsWith('000')
    ) {
      console.log('Prefixed Call')
      phone_to_parse_original = parseInt(
        this.props.infoCall._remote_identity.uri._user
      )
      phone_to_parse_asString = phone_to_parse_original.toString()
      phone_to_parse_plus = '+' + phone_to_parse_asString
    } else {
      console.log('French call with no prefix, default')
      phone_to_parse_original = parseInt(
        this.props.infoCall._remote_identity.uri._user
      )
      phone_to_parse_asString = '33' + phone_to_parse_original.toString()
      phone_to_parse_plus = '+' + phone_to_parse_asString
    }

    console.log('Detected country :')
    const detectedCountryCode = PhoneNumber(phone_to_parse_plus).getRegionCode()
    countryCode = detectedCountryCode
    console.log(detectedCountryCode)
    //await this.setState({countryCode: detectedCountryCode})

    console.log('componentDidUpdate ManagementCall')
    console.log(this.props)
    if (this.props.infoCall._is_confirmed) {
      await this.setGlobal({
        in_communication: true,
      })
    }
    await this.setGlobal({ infoCall: this.props.infoCall })
    const { isHold } = this.state
    if (this.props.infoHoldInCall === true) {
      this.props.infoCall.hold({
        useUpdate: false,
      })
      if (isHold === 'Hold' || isHold === null) {
        this.setState({
          isHold: 'UnHold',
        })
      }
    } else {
      if (isHold === 'UnHold') {
        this.setState({
          isHold: 'Hold',
        })
      }
      if (this.global.want_endcall) {
        this.endCall()
      }
    }
  }

  async componentWillUnmount() {
    clearTimeout(this.intervalID)
    await this.setGlobal({
      number: null,
      in_communication: null,
      minutes: null,
      seconds: null,
      timer_start: false,
      makeCall_clicked: false,
    })
  }

  dtmf = (e) => {
    e.preventDefault()
    console.log(e)
    if (e.key >= 0 && e.key <= 9) {
      this.props.infoCall.sendDTMF(e.key)
      this.setState({
        dtmf: this.state.dtmf + e.key,
      })
    }
  }

  dtmfGestion = () => {
    if (this.props.infoCall._status === 9 || this.props.infoCall._status === 6) {
      if (this.state.isDtmf !== false) {
        this.setState({
          isDtmf: false,
        })
      } else {
        this.setState({
          isDtmf: true,
          isTransfertSup: false,
          isTransfert: false,
        })
      }
    }
  }

  endCall = async () => {
    const own_extension = JSON.parse(localStorage.getItem('user'))

    await axios
      .get(
        'https://' +
          SRV_ENDPOINT +
          ':8082/dnd/set/off/' +
          own_extension.userInfo.extension
      )
      .then((res) => {
        console.log(res)
        this.setGlobal({
          callingFromWeb: false,
        })
      })
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    if (
      this.props.infoCall._status === 9 ||
      this.props.infoCall._status === 6 ||
      this.props.infoCall._status === 2 ||
      this.props.infoCall._status === 0
    ) {
      if (this.global.endCall_clicked) {
        console.log('Endcall already clicked')
      } else {
        await this.props.infoCall.terminate()
        await this.setGlobal({
          endCall_clicked: false,
        })
        await delay(5000)
        await this.setGlobal({
          number: null,
          in_communication: null,
          minutes: null,
          seconds: null,
          timer_start: false,
          makeCall_clicked: false,
        })
      }
    }
  }

  mute = () => {
    this.props.infoCall.mute({
      audio: true,
    })
    this.setState({
      isMute: 'Démuter',
    })
  }

  unMute = () => {
    this.props.infoCall.unmute({
      audio: true,
    })
    this.setState({
      isMute: 'Muet',
    })
  }

  Hold = async () => {
    this.props.infoCall.hold({
      useUpdate: false,
    })
    this.setState({
      isHold: 'UnHold',
    })
  }

  unHold = async () => {
    this.props.infoCall.unhold({
      useUpdate: true,
    })
    const audio = await document.createElement('audio')
    audio.srcObject = await this.props.infoCall.connection.getRemoteStreams()[0]
    audio.play()
    this.setState({
      isHold: 'Hold',
    })
    this.props.onClick(this.props.infoCall)
  }

  isMute = async () => {
    if (
      this.props.infoCall._status === 9 ||
      this.props.infoCall._status === 6 ||
      this.props.infoCall._status === 2
    ) {
      const valueMute = this.props.infoCall.isMuted()
      if (valueMute.audio) {
        this.unMute()
      } else {
        this.mute()
      }
    }
  }

  isOnHold = async () => {
    if (
      this.props.infoCall._status === 9 ||
      this.props.infoCall._status === 6 ||
      this.props.infoCall._status === 2
    ) {
      const valueHold = this.props.infoCall.isOnHold()
      if (valueHold.local) {
        this.unHold()
      } else {
        this.Hold()
      }
    }
  }

  transfertSupCall = async () => {
    if (this.props.infoCall._status === 9 || this.props.infoCall._status === 6) {
      const size = Object.keys(this.props.session).length
      if (size > 1) {
        if (this.state.isTransfertSup !== false) {
          this.setState({
            isTransfertSup: false,
          })
        } else {
          this.setState({
            isTransfertSup: true,
            isDtmf: false,
          })
        }
      }
    }
  }

  transfertSupChoice = async (numberCall, session, e) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    e.preventDefault()
    const { domain } = JSON.parse(localStorage.getItem('user')).infoServer
    this.props.infoCall.refer(`sip:${numberCall}@${domain}`)
    this.props.infoCall.terminate()
    session.terminate()
    await delay(5000)
    await this.setGlobal({
      number: null,
      in_communication: null,
      minutes: null,
      seconds: null,
      timer_start: false,
      makeCall_clicked: false,
    })
  }

  transfert = async (e) => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    if (this.props.infoCall._status === 9 || this.props.infoCall._status === 6) {
      e.preventDefault()
      const { domain } = JSON.parse(localStorage.getItem('user')).infoServer
      this.props.infoCall.refer(`sip:${this.state.number}@${domain}`)
      this.props.infoCall.terminate()
      await delay(5000)
      await this.setGlobal({
        number: null,
        in_communication: null,
        minutes: null,
        seconds: null,
        timer_start: false,
        makeCall_clicked: false,
      })
    }
  }

  stockNumber = async (e) => {
    await this.setState({
      number: e.target.validity.valid ? e.target.value : this.state.number,
    })
    await this.setGlobal({ number: null })
  }

  transfertCall = async () => {
    if (this.props.infoCall._status === 9) {
      if (this.state.isTransfert !== false) {
        this.setState({
          isTransfert: false,
        })
      } else {
        this.setState({
          isTransfert: true,
          isDtmf: false,
        })
      }
    }
  }

  displayMessage = () => {
    if (this.props.errorCall === 'User Denied Media Access') {
      return (
        <p style={{ color: '#e53935', fontWeight: 'bold' }}>
          Le site n'a pas accès au micro
        </p>
      )
    }
    switch (this.props.infoCall._status) {
      case 0:
        this.setGlobal({
          in_communication: true,
        })
        return <p style={{ fontWeight: 'bold' }}>Initialisation</p>
      case 2:
        this.setGlobal({ in_communication: true })
        return <p style={{ fontWeight: 'bold' }}>Sonnerie...</p>
      case 6:
        this.setGlobal({
          in_communication: true,
        })
        this.intervalID = setTimeout(this.timerCall.bind(this), 1000)
        return <p style={{ fontWeight: 'bold' }}>Connecté</p>
      case 8:
        this.setGlobal({
          in_communication: true,
        })
        this.intervalID = setTimeout(this.timerCall.bind(this), 1000)
        return <p style={{ fontWeight: 'bold' }}>Fin d'appel en cours</p>
      case 9:
        this.setGlobal({
          in_communication: true,
        })
        this.intervalID = setTimeout(this.timerCall.bind(this), 1000)
        return <p style={{ fontWeight: 'bold' }}>Connecté</p>
      default:
        this.setGlobal({
          in_communication: true,
        })
        this.intervalID = setTimeout(this.timerCall.bind(this), 1000)
        return <p style={{ fontWeight: 'bold' }}>Connecté</p>
    }
  }

  timerCall = (e) => {
    const countdown = moment(moment() - this.props.infoCall._start_time)
    const minutes = countdown.format('mm')
    const seconds = countdown.format('ss')
    this.setGlobal({ minutes, seconds, timer_start: true })
  }

  displayMuted = async () => {
    if (this.props.infoCall._status === 9) {
      return this.props.infoCall.isMuted().audio ? (
        <FontAwesomeIcon icon={faMicrophoneSlash} color="#f37027" />
      ) : (
        <FontAwesomeIcon icon={faMicrophone} color="#F3B733" />
      )
    }
    return <FontAwesomeIcon icon={faMicrophone} color="#F3B733" />
  }

  displayHold = () => {
    if (this.props.infoCall._status === 9) {
      return this.props.infoCall.isOnHold().local ? (
        <FontAwesomeIcon icon={faPlay} color="#f37027" />
      ) : (
        <FontAwesomeIcon icon={faPause} color="#F3B733" />
      )
    }
    return <FontAwesomeIcon icon={faPause} color="#F3B733" />
  }

  displayText = () => {
    if (this.props.infoCall._status === 9) {
      return this.props.infoCall.isOnHold().local ? 'Reprendre' : 'Pause'
    }
    return 'Pause'
  }

  render() {
    return (
      <div>
        <ContenairPopUpCall>
          <div style={{ color: '#149995', fontSize: '50px', fontWeight: 'bold' }}>
            {this.props.infoCall._remote_identity.uri._user}
          </div>
          <div style={{ color: '#149995', fontSize: '10px', fontWeight: 'bold' }}>
            {lookup.byInternet(countryCode).country}
          </div>
          <div style={{ color: '#149995', fontSize: '14px', fontWeight: '500' }}>
            {this.global.minutes}:{this.global.seconds}
          </div>
          <div style={{ color: '#149995', fontSize: '14px', fontWeight: '500' }}>
            {this.props.infoCall._remote_identity._display_name}
          </div>
          {this.displayMessage()}
          {this.state.isTransfert ? (
            <TransfertBlock>
              <form onSubmit={this.transfert}>
                <InputNumberTransfert
                  autoFocus
                  pattern="^(\+|[*]{0,1}[#]{0,1})[0-9.\(\)\s-]{0,}#{0,1}$"
                  value={this.state.number}
                  placeholder="Ecrivez votre numéro de transfert"
                  onChange={this.stockNumber.bind(this)}
                />
                <Button
                  onClick={this.transfert}
                  style={{
                    display: 'inline-block',
                    width: '65px',
                    float: 'right',
                    right: '0px',
                    position: 'absolute',
                    height: '100%',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  OK
                </Button>
              </form>
            </TransfertBlock>
          ) : null}
          {this.state.isDtmf ? (
            <InputNumberTransfert
              autoFocus
              pattern="^[*]{0,1}[#]{0,1}[0-9]{0,}$"
              value={this.state.dtmf}
              onKeyDown={this.dtmf.bind(this)}
            />
          ) : null}
          <ButtonMute onClick={this.isMute}>
            {this.props.infoCall.isMuted().audio ? (
              <FontAwesomeIcon icon={faMicrophoneSlash} color="#FF0000" />
            ) : (
              <FontAwesomeIcon icon={faMicrophone} color="#fafafa" />
            )}
            <br />
            {this.state.isMute}
          </ButtonMute>
          <ButtonHold onClick={this.isOnHold}>
            {this.props.infoCall.isOnHold().local ? (
              <FontAwesomeIcon icon={faPlay} color="#D89614" />
            ) : (
              <FontAwesomeIcon icon={faPause} color="#fafafa" />
            )}
            <br />
            {this.props.infoCall.isOnHold().local ? 'Reprendre' : 'Pause'}
          </ButtonHold>
          <DtmfButton>
            {this.state.isDtmf ? (
              <FontAwesomeIcon icon={faTh} color="#D89614" />
            ) : (
              <FontAwesomeIcon icon={faTh} color="#fafafa" />
            )}
            <br />
            Clavier
          </DtmfButton>
          <Transfert onClick={this.transfertCall}>
            {this.state.isTransfert ? (
              <FontAwesomeIcon icon={faExchangeAlt} color="#D89614" />
            ) : (
              <FontAwesomeIcon icon={faExchangeAlt} color="#46BE8A" />
            )}
            <br />
            Transfert
          </Transfert>
        </ContenairPopUpCall>
        <div id="stopcall" onClick={this.endCall}>
          <EndCall />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  session: state.session.session,
})

export default connect(mapStateToProps)(ManagementCall)
