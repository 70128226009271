import './App.css'
import './App.scss'

import React, { Component, setGlobal } from 'reactn'
import { Route, Switch } from 'react-router-dom'

import Page from './components/Page/Page'
import PopUpCall from './components/Page/PopUpCall/PopUpCall'
import PrivateRoute from './components/PrivateRoute'
import iconContact from './assets/icone/contact.png'
import iconHistorical from './assets/icone/historical.png'
import iconHome from './assets/icone/home.png'
import iconVoicemail from './assets/icone/messagerie.png'
import route from './components/utils/Constant'

// Set an initial global state directly:
setGlobal({
  data_actual_user: [],
  data_lines_status: [],
  data_lines_searched: [],
  data_lines_status_unsorted: [],
  data_history_logs: [],
  dnd_status: false,
  opened: false,
  in_communication: false,
  number: '',
  activeButton: null,
  minutes: null,
  seconds: null,
  timer_start: null,
  makeCall_clicked: false,
  endCall_clicked: false,
  want_endcall: false,
  incoming_call_active: false,
  alr_in_call: false,
  call_active_uuid: null,
  call_in_pause_uuid: null,
  while_incoming_call: null,
  missed_call: null,
  entryCallRing: false,
  callingFromWeb: false,
})

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <PrivateRoute
            exact
            path={'/'}
            component={() => <Page title="Dashboard" icon={iconHome} />}
          />
          <Route exact path={route.LOGIN} component={() => <Page title="Login" />} />
          <PrivateRoute
            exact
            path={route.HOME}
            component={() => <Page title="Dashboard" icon={iconHome} />}
          />
          <PrivateRoute
            exact
            path={route.CONTACTS}
            component={() => <Page title="Contacts" icon={iconContact} />}
          />
          <PrivateRoute
            exact
            path={route.CONTACTS_ADD}
            component={() => <Page title="Add a contact" icon={iconContact} />}
          />
          <PrivateRoute
            exact
            path={route.CONTACTS_MODIFY}
            component={() => <Page title="Edit contact" icon={iconContact} />}
          />
          <PrivateRoute
            exact
            path={route.HISTORY}
            component={() => <Page title="History" icon={iconHistorical} />}
          />
          <PrivateRoute
            exact
            path={route.VOICEMAIL}
            component={() => <Page title="Voicemail" icon={iconVoicemail} />}
          />
          <PrivateRoute
            exact
            path={route.USERS}
            component={() => <Page title="Users" icon={iconContact} />}
          />
        </Switch>
        <PopUpCall />
      </div>
    )
  }
}

export default App
